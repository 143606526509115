import { Routes, Route } from "react-router-dom";
import { CarritoProvider } from "./context/cartContext";
import { AlmacenProvider } from "./context/almacenVirtualContext";
import { BloqueadosProvider } from './context/productosBloqueados';
import { ExistenciasProvider } from "./context/existenciasContext";
import {  Actividades, Bloqueados, Buscar, CargarAlmacenesVirtuales, Desplazamientos, 
  BorrarMisPedidos, ExistenciasGlobales, Home, Login, MasterDePrecios, MisPedidos, Productos, 
  ReporteCeros, RevisarPedido, MisTraspasos, DashboardCompras } from './pages'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <>
     <ToastContainer />
        <AuthProvider>
          <AlmacenProvider>
            <BloqueadosProvider>
              <CarritoProvider>
                <ExistenciasProvider>
                  <Routes>
                    <Route path='/' element={<Login />}/>
                    <Route path='/home' element={<Home />}/>
                    <Route path='/productos/:departamento' element={<Productos />}/>
                    <Route path='/busqueda/:buscar' element={<Buscar />}/>
                    <Route path='/revisar-pedido' element={<RevisarPedido />}/>
                    <Route path='/mis-pedidos' element={<MisPedidos />}/>
                    <Route path='/master' element={<MasterDePrecios />}/>
                    <Route path='/reporte-ceros' element={<ReporteCeros />}/>
                    <Route path='/almacenes' element={<CargarAlmacenesVirtuales />}/>
                    <Route path='/desplazamientos' element={<Desplazamientos />}/>
                    <Route path='/bloqueados' element={<Bloqueados />}/>
                    <Route path='/existencias' element={<ExistenciasGlobales />}/>
                    <Route path='/borrar' element={<BorrarMisPedidos />}/>
                    <Route path='/traspasos' element={<MisTraspasos />}/>
                    <Route path='/actividades' element={<Actividades />}/>
                    <Route path='/dashboard-compras' element={<DashboardCompras />}/>
                  </Routes>
                </ExistenciasProvider>
              </CarritoProvider>
            </BloqueadosProvider>
          </AlmacenProvider>
        </AuthProvider>
    </>
  );
}

export default App;
