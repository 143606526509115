import { db } from './firebaseConfig';
import { doc, setDoc  } from "firebase/firestore";

const actividadEnLaAplicacion = async ({userActividad, fechaActividad, modulo, accion}) => {
    await setDoc(doc(db, "actividad", userActividad + '-' + fechaActividad + '-' + modulo), {
        sucursal: userActividad,
        fechaActividad: fechaActividad,
        modulo: modulo,
        accion: accion
      });
}

export default actividadEnLaAplicacion;