import { useState } from 'react';
import { MenuPrincipal } from './../components/MenuPrincipal';
import { getDashboardComprasRequest } from '../api/apiFarmaprontoCentro';
import { useEffect } from 'react';
import formatoMoneda from '../functions/formatMoneda';

export function DashboardCompras () {

    const [datos, setDatos] = useState([])

    const getDatosCompras = async () =>{
        const res = await getDashboardComprasRequest()
        setDatos(res.data)
    }
    useEffect(() => {
      getDatosCompras()
    }, [])

    const compraNadro = datos.filter((item) => item.nadro > 0).reduce((a,b) => a + b.precionadro * b.nadro,0)
    const compraMarzam = datos.filter((item) => item.marzam > 0).reduce((a,b) => a + b.preciomarzam * b.marzam,0)
    const compraCedifa = datos.filter((item) => item.cedifatraspaso > 0).reduce((a,b) => a + b.preciocedifatraspaso * b.cedifatraspaso,0)
    const compraAlmacenes = datos.filter((item) => item.almacenesvirtuales > 0).reduce((a,b) => a + b.precioalmacenesvirtuales * b.almacenesvirtuales,0)
    const importeTotal = compraCedifa + compraAlmacenes + compraMarzam + compraNadro
    

    return(
        <>
            <MenuPrincipal />
            <div className='wrap'>
                <div className='container-cards-dashboard'>
                    <div className='card-dashboard'>
                        <div className='head-card-dashboard'>
                            <h2>Cedifa</h2>
                            <span>{Math.round((compraCedifa/importeTotal)*100)}%</span>
                        </div>
                        <span className='importeCompra'>{formatoMoneda(compraCedifa)}</span>
                    </div>
                    <div className='card-dashboard'>
                        <div className='head-card-dashboard'>
                            <h2>Nadro</h2>
                            <span>{Math.round((compraNadro/importeTotal)*100)}%</span>
                        </div>
                        <span className='importeCompra'>{formatoMoneda(compraNadro)}</span>
                    </div>
                    <div className='card-dashboard'>
                        <div className='head-card-dashboard'>
                            <h2>Marzam</h2>
                            <span>{Math.round((compraMarzam/importeTotal)*100)}%</span>
                        </div>
                        <span className='importeCompra'>{formatoMoneda(compraMarzam)}</span>
                    </div>
                    <div className='card-dashboard'>
                        <div className='head-card-dashboard'>
                            <h2>Almacenes Virtuales</h2>
                            <span>{Math.round((compraAlmacenes/importeTotal)*100)}%</span>
                        </div>
                        <span className='importeCompra'>{formatoMoneda(compraAlmacenes)}</span>
                    </div>             
                </div>
            </div>

        </>
    )
}