import styled from 'styled-components';


const CardContainer = styled.div`
    background-color: white;
    cursor: pointer;
    width: 200px;
    height: 395px;
    padding: 6px;
    overflow: hidden;
    position: relative;
    margin-block: 6px;
    margin-inline: 6px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(209,209,209,1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(209,209,209,1);
    box-shadow: 0px 0px 10px 0px rgba(209,209,209,1);
`;

const CardHeader = styled.div`
    position: absolute;
    width: 94%;
    margin: auto;
    display: flex;
    align-items: center;
`;

const ItemDepartamento = styled.span`
    background-color: #eaeaea;
    color: #696969;
    font-size: 12px;
    padding: 4px;
    border-radius: 2px;
`;

const ImgContainer = styled.div`
    width: 190px;
    height: 190px;
    margin: 0px auto;
    img{
        width: 100%;
    }
`;

const CardBody = styled.div`
    color: #696969;
    padding: 2px 0px 8px 0px;
`;

const GroupTextCard = styled.div`
    margin: 10px auto;
    height: 60px;
    width: 90%;
    text-align: center;
    .item-codigo{
        font-size: 12px;
        text-align: center;
        line-height: 10px;
        display: block;
    }
    .item-nombre{
        font-size: 12px;
        line-height: 12px;
        overflow: hidden;
        text-overflow: hidden;
    }
    .item-existencias{
        font-size: 12px;
        line-height: 10px;
    }
    .item-precio{
        color: #1976d2;
        font-weight: 600;
        font-size: 22px;
    }
`;

const FooterCard = styled.div`
    width: 90%;
    overflow: hidden;
    margin: 0px auto;
`;

const ControllerContainer = styled.div`
    display: flex;
    justify-content: center;
    button{
        margin-inline: 6px;
    }
    span{
        color: #696969;
        font-size: 14px;
    }
`;


export { CardContainer, CardHeader, ItemDepartamento, ImgContainer, CardBody, GroupTextCard, FooterCard, ControllerContainer };