import React, { useState } from 'react';
import { auth } from './../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import imgLogin from './../assets/img/login.png';
import { ToastContainer, toast } from 'react-toastify';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

export function Login () {
    const navigate = useNavigate();
    const [correo, setCorreo] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();

      };


    const handleChange = (e) =>{
        if(e.target.name === 'password'){
            setPassword(e.target.value);
        }else if(e.target.name === 'correo'){
            setCorreo(e.target.value);
        }
    }

    console.log(correo, password)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(correo === '' || password === ''){
            // toast.error('Falta llenar los campos de usuario y pass');
        }
        
        try{
            const infoUsuario = await signInWithEmailAndPassword(auth, correo, password);
            if(infoUsuario.user.email === 'cuentasporpagar-2@farmaprontocentro.com'){
                navigate('/facturas')
            }else{
                navigate('/home');
            }
            
        }catch (e){
            toast.error('Upps! Salio algo mal, intenta de nuevo', {
                position: "top-center",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
            });
        }
    }
    
    return(
        <div className='container-login'>
            <div className='wrap-login'>
                <div className='img-login'>
                    <img src={imgLogin} />
                </div>
                <div className='form-login'>
                    <form onSubmit={handleSubmit}>
                        <h2>Iniciar sesión</h2>
                        <FormControl variant="standard" sx={{ m: 2, minWidth: 280 }} size="small">
                            <InputLabel id="demo-simple-select-standard-label">Usuario</InputLabel>
                            <Select
                            name="correo"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={correo}
                            onChange={handleChange}
                            label="Usuario"
                            >
                            <MenuItem value="">
                                <em>Ninguna</em>
                            </MenuItem>
                            <MenuItem value="cedifa@farmaprontocentro.com">Cedifa</MenuItem>
                            <MenuItem value="maravillas.ft@farmaprontocentro.com">Maravillas</MenuItem>
                            <MenuItem value="carmelo.ft@farmaprontocentro.com">Carmelo</MenuItem>
                            <MenuItem value="estado.ft@farmaprontocentro.com">Estado</MenuItem>                      
                            <MenuItem value="mayoreo2.ft@farmaprontocentro.com">Mayoreo II</MenuItem>
                            <MenuItem value="romero.ft@farmaprontocentro.com">Romero</MenuItem>
                            <MenuItem value="lido.ft@farmaprontocentro.com">Lido</MenuItem>
                            <MenuItem value="ecatepec.mgd@farmaprontocentro.com">Ecatepec</MenuItem>
                            <MenuItem value="losreyes@farmaprontocentro.com">Reyes</MenuItem>
                            <MenuItem value="basilica@farmaprontocentro.com">Basílica</MenuItem>
                            <MenuItem value="administracion.sistemas2@farmaprontocentro.com">Administración</MenuItem>
                            <MenuItem value="cuentasporpagar-2@farmaprontocentro.com">Cuentas</MenuItem>
                            <MenuItem value="compras@farmaprontocentro.com">Iván</MenuItem>
                            <MenuItem value="sistemas@farmaprontocentro.com">Sistemas</MenuItem>
                            </Select>
                        </FormControl><br />

                        <FormControl sx={{ m: 2, width: '280px' }} variant="standard">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                id="password"
                                name='password'
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                value={password}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Button variant="contained"
                            type='submit'
                            disableElevation 
                            size="medium" sx={{ m: 2, width: '280px' }}
                            onClick={handleSubmit}
                        >
                            Entrar
                        </Button>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}