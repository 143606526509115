import React,{ useEffect, useState } from 'react';
import { getDesplazamientosRequest } from '../api/apiFarmaprontoCentro';
import { Container } from './../elements/Elementos';
import { ColumnaHeader, ColumnaTabla, FilaTabla, Tabla, HeaderTablaPop } from '../elements/TablaElements';
import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';
// Mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function Desplazamientos(){
  // States
  const [codigoBuscado, setcodigoBuscado] = useState('');
  const [desplazamientos, setDesplazamientos] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // Obetngo los Datos de la Api
  const getDesplazamientos = async () => {
    const res = await getDesplazamientosRequest(codigoBuscado);
    setDesplazamientos(res.data)
  }
  useEffect(() => {
    getDesplazamientos()
  }, [codigoBuscado])
    
    
  const handleKeyDown = (e) => {
    if(e.keyCode === 13){
      setcodigoBuscado(e.target.value)
    }
  }
  
  const handleDrawerClose = () => {
    setOpen(false);
  };



    return(
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarHeader open={open} setOpen={setOpen}/>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
            <Menu open={open}/>
        </Drawer>
        <Box component="main" style={{width: '100%'}}>
            <Container>
                <input type="search" className="inputcodigo" placeholder="Buscar producto por codigo" onKeyDown={handleKeyDown} />
                
                <Tabla size='auto' margin='20px'>
                    <HeaderTablaPop bgColorLight>
                        <ColumnaHeader  bgColorLight>Código</ColumnaHeader>
                        <ColumnaHeader  bgColorLight>Nombre</ColumnaHeader>
                        <ColumnaHeader  bgColorLight>Sucursal</ColumnaHeader>
                        <ColumnaHeader  bgColorLight>Desplazamiento 10 dias</ColumnaHeader>
                    </HeaderTablaPop>
                    <tbody>
                        {
                            desplazamientos.map(({codigo, nombre, sucursal, cantidad}) => {
                                return(
                                    <FilaTabla  bgColorLight>
                                        <ColumnaTabla>{codigo}</ColumnaTabla>
                                        <ColumnaTabla>{nombre}</ColumnaTabla>
                                        <ColumnaTabla>{sucursal}</ColumnaTabla>
                                        <ColumnaTabla align='center'>{cantidad}</ColumnaTabla>
                                    </FilaTabla>
                                )
                            })
                        }
                    </tbody>
                </Tabla>

            </Container>
        </Box>
      </Box>
    )
}