import { db } from './firebaseConfig';
import { doc, setDoc } from "firebase/firestore";

const enviarPedido = async (order) => {

    const data = await order[0].pedido.map(({codigo, nombre, pedido, precio, cedifa}) => {
        return (
            {
                codigo: codigo,
                pedido: pedido,
                nombre: nombre,
                precio: precio,
                cedifa: cedifa
               
            }
        )
    });

    console.log('data: ',data);

    await setDoc(doc(db, "pedidoscedifa", order[0].idpedido), {
        sucursal: order[0].sucursal,
        idPedido: order[0].idpedido,
        fechaPedido: order[0].fechaPedido,
        productos: data
      });
}

export default enviarPedido;