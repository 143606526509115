import React,{ useState, useContext } from "react";
import { ContextoCarrito } from '../context/cartContext';
import formatMoneda from './../functions/formatMoneda';
import imgCartNotFound from './../assets/img/cart.png';
import { getUnixTime } from 'date-fns';
import enviarPedido from './../firebase/enviarPedido';
import { useAuth } from '../context/AuthContext';
import { useExistencias } from '../context/existenciasContext';
import { ToastContainer, toast } from 'react-toastify';


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export function Carrito({state, setState, toggleDrawer}){
    const { countCart, productosCarrito, importeCart, deleteProductoCart, vaciarCarrito } = useContext(ContextoCarrito);
    const { usuario } = useAuth();
    const { existencias } = useExistencias();
    const userSucursal = usuario.displayName;

    const imagenDefault = (e) =>{
        e.target.src =  'https://farmaprontoneza.com/image/predeterminada.jpg' 
    }


    let arrayPedidoEnviar = [];
    productosCarrito.forEach((item) => {
        const buscarProducto = existencias.find((itemE) => parseInt(itemE.codigo) === parseInt(item.codigo))
        const addArrayPedidoEnviar = {
            codigo: parseInt(item.codigo),
            nombre: item.nombre,
            pedido: item.pedido,
            precio: item.precio,
        }
        if(buscarProducto){
            addArrayPedidoEnviar.cedifa = buscarProducto.existencia
        }else{
            addArrayPedidoEnviar.cedifa = 0
        }

        arrayPedidoEnviar.push(addArrayPedidoEnviar);
    })

    const formatoPedidoAEnviar = [
        {
            idpedido: 'AG-' + userSucursal + '-' + getUnixTime(new Date()) ,
            sucursal: userSucursal,
            fechaPedido: getUnixTime(new Date()),
            pedido: arrayPedidoEnviar
        }
    ];

    const handleSend = (e) =>{
        if(arrayPedidoEnviar.length > 0){
            enviarPedido(formatoPedidoAEnviar);
            vaciarCarrito()
            toast.success('Perfecto, se envio el pedido correctamente', {
                position: "bottom-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "dark",
            });
        }else{
            toast.success('Que paso señor, no ha ingresado nada en el carrito', {
                position: "bottom-right",
                autoClose: 2600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "dark",
            });
        }
                      
    }
    
      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450 , height: '100%'}}
          role="presentation"
          className='cart-container'
        >

            {
                productosCarrito.length > 0
                ?
                <List className='wrap-rows'>
                    {
                        productosCarrito.map(({codigo, nombre, pedido, precio, importe}) => {
                            return(
                                <>
                                    <div className="row-cart-show">
                                        <div><img loading="lazy" onError={imagenDefault} src={'https://farmaprontoneza.com/image/' + parseInt(codigo, 10) + '.jpg'} alt={nombre} /></div>
                                        <div className="info-cart-show">
                                            <span>{nombre}</span> <br />
                                            <span>{pedido > 1 ? pedido+' piezas' : pedido+' pieza'} </span> <br/>
                                            <span>Precio: {formatMoneda(precio)} Importe: {formatMoneda(importe)}</span> <br/>
                                        </div>
                                        <div>
                                            <IconButton aria-label="delete" onClick={()=> deleteProductoCart(codigo)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <Divider />
                                </>
                                
                            )
                        })
                    }
                </List>
                : 
                <List className='wrap-img'>
                    <img className='img-cart' src={imgCartNotFound} />
                </List>
            }
          
          <List className="wrap-total-cart">
            <h3>Total: {formatMoneda(importeCart)}</h3>
            <Button  variant="outlined" endIcon={<DeleteIcon />} onClick={vaciarCarrito}>
                Vaciar Carrito
            </Button>
            <Button variant="contained" endIcon={<SendIcon />} onClick={handleSend}>
                Finalizar
            </Button>
          </List>
          
        </Box>
      );




    return(
        <Drawer
            anchor='right'
            open={state['right']}
            onClose={toggleDrawer('right', false)}
        >
            {list('right')}
        </Drawer>
    )
}