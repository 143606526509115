import React,{ useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useExistencias } from '../context/existenciasContext';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ContextoCarrito } from '../context/cartContext';
import { useAuth } from './../context/AuthContext';
import { signOut, updateProfile } from "firebase/auth";
import { auth } from './../firebase/firebaseConfig';
import uriownload from './../assets/Ortopedia.xlsx';
import { SiMicrosoftexcel } from 'react-icons/si';


//Iconos
import { FcTodoList, FcComboChart, FcCurrencyExchange, FcBookmark, FcHome,
     FcShipped, FcFinePrint, FcAcceptDatabase, FcShop, FcUndo, FcInspection, FcGlobe } from 'react-icons/fc'
import { Carrito } from "./Carrito";
import { PopUpNuevos } from "./PopUpNuevos";
import { LeakRemoveTwoTone } from "@mui/icons-material";
import actividadEnLaAplicacion from "../firebase/actividadEnLaAplicacion";
import { getUnixTime } from "date-fns";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -4,
        top: 12,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
    }));

export function MenuPrincipal (){
    const { existencias, arrayMenu} = useExistencias();
    const [busqueda, setBusqueda] = useState('');
    const [state, setState] = React.useState({right: false,});

    const {usuario} = useAuth();
    const { countCart } = useContext(ContextoCarrito);

    let userActividad = usuario.displayName;
    let fechaActividad = getUnixTime(new Date())
    const modulo = 'Agregados'
    const accion = 'Abrir Pop Up de nuevas recepciones'
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setState({ ...state, [anchor]: open });
    };

    let navigate = useNavigate();

    const sortArray = (x, y) =>{
        if(x.familia < y.familia) {return -1;}
        if(x.familia > y.familia) {return  1;}
        return 0;
    }
    let itemsMenu = arrayMenu.sort(sortArray);

    const handleChangeBusqueda = (event) => {
        setBusqueda(event.target.value);
    }

    const handleEnter = (event) => {
        setBusqueda(event.target.value);
        if(event.keyCode == 13){
            navigate('/busqueda/'+ busqueda);
        }
    }
    const menuSkeleton = [1,2,3,4,5,6,7,8];



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion  })
    };

  const logout = async () => {
    navigate('/')
    await signOut(auth);
  }

//   const cambiar= () =>{
//     updateProfile(auth.currentUser, {
//             displayName: "SISTEMAS"
//           }).then(() => {
//             alert('Listo')
//             // ...
//           }).catch((error) => {
//             alert(error)
//             // ...
//           });
//   }  
    return(
        <>
        <PopUpNuevos open={open} setOpen={setOpen}/>
        <nav>
            <div>
                <h1>Farma Admin <span>V-2.0</span></h1>
                {/* <button onClick={cambiar}>Cambiar</button> */}
            </div>
            <ul className="item-ul-menu">
                <li className="item-lista">
                    <Link to='#'><FcTodoList /> Administración</Link>
                    <ul className="item-ul-submenu">
                        <li className="item-sublista"><Link to='/desplazamientos'><FcComboChart /> Dezplazamiento</Link></li>
                        <li className="item-sublista"><Link to='/master'><FcCurrencyExchange /> Master</Link></li>
                        <li className="item-sublista"><Link to='/reporte-ceros'><FcInspection /> Reporte Ceros</Link></li>
                    </ul>
                </li>
                <li className="item-lista">
                    <Link to='#'><FcShipped /> Pedidos</Link>
                    <ul className="item-ul-submenu">
                        <li className="item-sublista"><Link to='/revisar-pedido'><FcFinePrint /> Revisar pedido</Link></li>
                        <li className="item-sublista"><Link to='/mis-pedidos'><FcAcceptDatabase />Mis pedidos</Link></li>
                    </ul>
                </li>
                <li className="item-lista">
                    <Link to='/traspasos'><FcFinePrint /> Mis Traspasos</Link>
                </li>
                <li className="item-lista"><Link to='#' onClick={handleClickOpen}><FcBookmark /> Nuevos Ingresos</Link></li>
                <li className="item-lista"><Link to='/existencias' ><FcGlobe /> Existencias Globales</Link></li>
                {
                    usuario.displayName === 'SISTEMAS' || usuario.displayName === 'IVAN'
                    ?<li className="item-lista"><Link to='/almacenes'><FcBookmark /> Almacen</Link></li>
                    :''
                }
                
                <li className='item-menu'>
                    <button className='btnDownload btnortopedia'>
                        <a href={uriownload} target="_blank" rel="noopener noreferrer" download="Ortopedia.xlsx"> 
                            <SiMicrosoftexcel />
                            <span>Ortopedia HM</span>
                        </a>
                    </button>
                </li>
            </ul>
            <ul className="item-ul-menu data-users">
                <li className="item-lista list-cart">
                <IconButton aria-label="cart" onClick={toggleDrawer('right', true)}>
                    <StyledBadge badgeContent={countCart} color="primary">
                        <ShoppingCartIcon className="icon-cart"/>
                    </StyledBadge>
                </IconButton>
                </li>
                <li className="item-lista list-user">
                    <Link to='#'><FcShop /> {usuario.displayName}</Link>
                    <ul className="item-ul-submenu">
                        <li className="item-sublista"><Link to='/' onClick={logout}><FcUndo /> Cerrar sesión</Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
        <div className="wrap-skills">
                <div className="wrap-navigate">
                    <input 
                        type='search'
                        placeholder="Buscar producto..." 
                        onChange={handleChangeBusqueda}
                        onKeyDown={handleEnter}
                    />
                    <nav className="menu-deptos">
                        <ul>
                            <li><Link to="/home"><FcHome /> Home</Link></li>
                            {
                                itemsMenu.length === 0 
                                ?
                                <div className="skeleton-menu">
                                    {
                                        menuSkeleton.map(() => <Stack spacing={1}><Skeleton variant="rounded" width={120} height={30} /></Stack>)
                                    }
                                </div>
                                :
                                itemsMenu.map(({familia, departamentos}) => {
                                    return(
                                        <li key={familia}>
                                            <Link to={'/productos/' + familia}>{familia}</Link>
                                            <ul className="submenu-deptos">
                                                {
                                                    departamentos.map(departamento => {
                                                        return(
                                                            <li><Link to={'/productos/' + departamento}>{departamento}</Link></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    )  
                                })
                            }
                        </ul>
                    </nav>
                                        
                </div>
            </div>


            <div>
                <Carrito state={state} toggleDrawer={toggleDrawer}/>
            </div>
        </>
    )
}