import React, { useState, useEffect } from "react";
import { MenuPrincipal } from "../components/MenuPrincipal";
import { getPedidosGeneradosRequest, getPreciosMayoristasRequest } from './../api/apiFarmaprontoCentro';
import { useExistencias } from '../context/existenciasContext';
import ReactExport from "react-export-excel";
import { SiMicrosoftexcel } from 'react-icons/si';
import * as XLSX from 'xlsx';
import { Menu } from './../components/Menu';
import { useAuth } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import { getUnixTime } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import { useAlmacenVirtual } from "../context/almacenVirtualContext";
import { useProductosBloqueados } from "../context/productosBloqueados";
import { TablaFiltrarPedidos } from "../components/TablaFiltrarPedidos";
import enviarPedido from './../firebase/enviarPedido';
import actividadEnLaAplicacion from "../firebase/actividadEnLaAplicacion";
import { SkeletonTablaRevisarPedidos } from "../components/SkeletonTablaRevisarPedidos";
import { AppBarHeader } from '../components/AppBarHeader';
import { styled, useTheme } from '@mui/material/styles';

// MUI
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Container } from './../elements/Elementos';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export function RevisarPedido(){
    // Estados de la aplicación
    const [loading, setLoading] = useState(false);
    const [proveedorSelect, setProveedorSelect] = useState('');
    const [pedido, setPedido] = useState([]);
    const [pedidosGenerados, setPedidosGenerados] = useState([]);
    const [pedidoSeleccionado, setPedidoSeleccionado] = useState([]);
    const [preciosMayoristas, setPreciosMayoristas] = useState([])
    const [clickSelect, setClickSelect] = useState(false);
    const [piezas, setPiezas] = useState(0)

    const { existencias } = useExistencias();
    const { almacenVirtual } = useAlmacenVirtual();
    const { productosBloqueados } = useProductosBloqueados();
    const [showSkeleton, setShowSkeleton] = useState(false)
    const { usuario } = useAuth();

    const userSucursal = usuario.displayName;
    let userActividad = usuario.displayName;
    let fechaActividad = getUnixTime(new Date())
    const modulo = 'Revisar Pedido'
    const accion = 'Seleccionar pedido'

    // ### Consumir el Api FarmaprontoCentro - Pedidos generados
    const getPedidosGenerados = async () => {
        const res = await getPedidosGeneradosRequest(userSucursal)
        setPedidosGenerados(res.data)
    }

    // ### Ejectamos la funcion que lee el Api cada que seleccione el select
      useEffect(() => {
          getPedidosGenerados()
      }, [clickSelect]);

     // ### Consumir el Api FarmaprontoCentro - Pedidos generados
     const getPreciosMayoristas = async () => {
        const res = await getPreciosMayoristasRequest()
        setPreciosMayoristas(res.data)
    }
 
    // ### Ejectamos la funcion que lee el Api
    useEffect(() => {
        getPreciosMayoristas();
    },[clickSelect ,pedido])

    // ###HandleChange Select Filtrar Proveedores
    const handleChange = (event) => {
      setProveedorSelect(event.target.value);
    };

    const handleClickSelect = () => {
        setClickSelect(!clickSelect)
        setShowSkeleton(true)
    }

     // ###HandleChange Select Pedido Generados
    const handleChangePedidosGenerados = (event) => {
        // let idpedido = event.target.value
        setPedidoSeleccionado(event.target.value)
        setPedido(pedidosGenerados.filter((item => item.idpedido === event.target.value)))
        actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion})
    };

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
  
    // const handleDrawerOpen = () => {
    //   setOpen(true);
    // };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    // const handleClickOpen = (codigo) => {
    //     setOpen(true);
    // };

    const readExcel = (file) =>{
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = (e) => {
                const buffeArray = e.target.result;
                const wb = XLSX.read(buffeArray, {type: 'buffer'});
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data=XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error)
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 1600,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "dark",
                });
            };
        });

        promise.then((d) => {
            setPedido(d);
            toast.success('Se cargo el pedido correctamente!', {
                position: "bottom-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "dark",
            });
        });
    };
    
    // ### Fucion para Btn Enviar, pasa a Estado Carjando....
    function handleClick() {
        setLoading(true);
    }

    // ### Fucion para quitar los repetidos
    function onlyUnique(value, index, self) { 
        return self.indexOf(value) === index;
    }

    // ### Obtengo los proveedores sin repetidos del array PedidoGeneados
    let allProveedores = pedidosGenerados.map(item => item.proveedor);
    let proveedores = [... new Set(allProveedores)];


    let allPedidos = pedidosGenerados.map(item => item.idpedido);
    let pedidos = [... new Set(allPedidos)];

    // ### Genero el arra de los Pedidos con Proveedor y su idPedido correspondientes
    let arrayPedidosGenerados = [];
    proveedores.forEach((proveedor) => {
        const buscarPedidos = pedidosGenerados.filter((item) => item.proveedor === proveedor)
        const addPedidosGenerados = {
            proveedor: proveedor
        }
        if(buscarPedidos){
            addPedidosGenerados.pedidos = buscarPedidos.map(item => item.idpedido).filter(onlyUnique).map(idpedido => idpedido)
        }
        arrayPedidosGenerados.push(addPedidosGenerados)
    })

    let arrayPedido = [];

    pedido.forEach((itemPedido) => {
        const buscarExistenciasCedifa = existencias.find((item) => parseInt(item.codigo) === parseInt(itemPedido.codigo))
        const buscarPreciosMayoristas = preciosMayoristas.find((item) => parseInt(item.codigo) === parseInt(itemPedido.codigo))

        const addArrayPedido = {
            codigo: parseInt(itemPedido.codigo),
            pedido: itemPedido.pedido,
            nombre: itemPedido.nombre,
        }
        if(buscarExistenciasCedifa){
            addArrayPedido.cedifa = buscarExistenciasCedifa.existencia
        }else{
            addArrayPedido.cedifa = 0
        }
        if(buscarPreciosMayoristas){
            addArrayPedido.nadro = buscarPreciosMayoristas.nadro;
            addArrayPedido.marzam = buscarPreciosMayoristas.marzam;
            if(userSucursal === 'MAYOREO 2' || userSucursal === 'BASILICA'){
                addArrayPedido.farmacos = buscarPreciosMayoristas.farmacos;
            }else{
                addArrayPedido.farmacos = 0
            }
        }else{
            addArrayPedido.nadro = 0;
            addArrayPedido.marzam = 0;
            addArrayPedido.farmacos = 0
        }

        let almacenSeleccionado = 'ALMACEN SUR'
        if(userSucursal === 'ECATEPEC' || userSucursal === 'BASILICA' ){
            almacenSeleccionado = 'ALMACEN NORTE'
        }

        const buscarPrecioEnAlmacenVirtual = almacenVirtual.find((item) => parseInt(item.codigo,10) === parseInt(itemPedido.codigo,10) && item.almacen === almacenSeleccionado);
        if(buscarPrecioEnAlmacenVirtual){
            addArrayPedido.almacenVirtual = buscarPrecioEnAlmacenVirtual.precio
        }else{
            addArrayPedido.almacenVirtual = 0
        }

        const buscarProductosBloqueados = productosBloqueados.find((item) => parseInt(item.codigo,10) === parseInt(itemPedido.codigo,10))
        if(buscarProductosBloqueados){
            addArrayPedido.unlocked = 'unlocked'
        }else{
            addArrayPedido.unlocked = ''
        }

        let precios = [addArrayPedido.nadro, addArrayPedido.marzam, addArrayPedido.farmacos, addArrayPedido.almacenVirtual]
        const arrayPrecios = [];

        for(let index = 0; index < precios.length; index++){
            const precio = precios[index];
            if(precio > 0){
                arrayPrecios.push(precio);
            }
        }

        addArrayPedido.mejorprecio = arrayPrecios.length > 0 ? Math.min.apply(null, arrayPrecios) : 0;
        if(addArrayPedido.cedifa > 0){
            addArrayPedido.proveedor = 'CEDIFA';
        }else if(addArrayPedido.mejorprecio === 0){
            addArrayPedido.proveedor = 'Sin Proveedor';
        }else if(addArrayPedido.mejorprecio === addArrayPedido.nadro){
            addArrayPedido.proveedor = 'Nadro';
        }else if(addArrayPedido.mejorprecio === addArrayPedido.marzam){
            addArrayPedido.proveedor = 'Marzam';
        }else if(addArrayPedido.mejorprecio === addArrayPedido.farmacos){
            addArrayPedido.proveedor = 'Farmacos';
        }else if(addArrayPedido.mejorprecio === addArrayPedido.almacenVirtual){
            addArrayPedido.proveedor = 'Almacen Virtual'
        }

        arrayPedido.push(addArrayPedido);
    })

    const arrayPedidos = arrayPedido.map(item => item.proveedor)
    const proveedoresiltrados = [... new Set(arrayPedidos)];


    const pedidoFinal = proveedorSelect === '' ? arrayPedido : arrayPedido.filter(item => item.proveedor === proveedorSelect);


    const agregarItem = ({codigo, pedido, cantidadInput}) =>{
        if(pedidoFinal.find(p => p.codigo === codigo)){
            setPedido(pedidoFinal.map(p => (p.codigo === codigo ? {...p, pedido: p.pedido + pedido} : p)));
            toast.success(`Se agregaron ${pedido} pzs al pedido`, {
                position: "top-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: 0,
                theme: "light",
            });
        }else if(cantidadInput === 0){
            let newPedido = pedido.filter(x => x.codigo != codigo);
            setPedido(newPedido)
        }
    }
    const removerItem = ({codigo}) =>{
        if(pedidoFinal.find(p => p.codigo === codigo)){
            if(pedidoFinal.find(p => p.codigo === codigo & p.pedido === 1)){
                let newPedido = pedido.filter(x => x.codigo != codigo);
            setPedido(newPedido)
            }else{
                setPedido(pedidoFinal.map(p => (p.codigo === codigo ? {...p, pedido: p.pedido - 1} : p)));
            }
            toast.success('Se quito 1 pieza', {
                position: "top-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: 0,
                theme: "light",
            });
        }
    }

    const borrarItem = (codigo) =>{
        let newPedido = pedido.filter(x => x.codigo != codigo);
        setPedido(newPedido)
        toast.success('Se elimino 1 articulo', {
            position: "top-right",
            autoClose: 1600,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
        });
    }
    
    const limpiar = () =>{
        setPedido([]);
        setPedidoSeleccionado('')
        setShowSkeleton(false)
        toast.success('Se limpio la tabla de pedido', {
            position: "top-right",
            autoClose: 1600,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: 0,
            theme: "light",
        });
    }

    const printData =() =>{
        var divToPrint=document.getElementById("tablaRevisarPedido");
        if(pedido.length > 0){
            let newWin= window.open("");
            newWin.document.write(`
                <style>
                    table{
                        width: auto; 
                        font-size: 10px;
                        font-family: Arial;
                    }
                    table tbody td{
                        border: 1px solid black;
                    }
                    table thead th:nth-child(n + 4){
                        display: none;
                    }
                    table thead th:nth-child(11){
                        display: block;
                    }
                    table thead th:nth-child(12){
                        display: none;
                    }
                    table tbody td:nth-child(2){
                       text-align: center;
                    }
                    table tbody td:nth-child(3){
                        width: 300px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    table tbody td:nth-child(11){
                        width: 60px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    table tbody td:nth-child(2) button{
                        display: none;
                    }
                    table tbody td:nth-child(n + 4){
                        display: none;
                    }
                    .precio-tabla{
                        display: block!important;
                    }

                    .unlocked{
                        display: none;
                    }
                </style>
            `);
            newWin.document.write(divToPrint.outerHTML);
            newWin.print();
            newWin.close();
        }else{
            // toast.error("Oops! No hay nada que imprimir.");
        }        
    }

    let arrayPedidoEnviar = [];
    pedidoFinal.filter(x => x.proveedor === 'CEDIFA' && x.pedido > 0).forEach((item) => {
        const addArrayPedidoEnviar = {
            codigo: parseInt(item.codigo),
            nombre: item.nombre,
            pedido: item.pedido,
            precio: item.mejorprecio,
            cedifa: item.cedifa
        }
        arrayPedidoEnviar.push(addArrayPedidoEnviar);
    })


    const formatoPedidoAEnviar = [
        {
            idpedido: 'PD-'+ userSucursal + '-' + getUnixTime(new Date()) ,
            sucursal: userSucursal,
            fechaPedido: getUnixTime(new Date()),
            pedido: arrayPedidoEnviar
        }
    ];

    const handleSend = (e) =>{
        enviarPedido(formatoPedidoAEnviar);
        setPedido([]);               
        actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion: `Se envio el pedido a CEDIFA`  })
    }

    let Fecha = new Date();
    let mes = Fecha.getMonth()+1

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const handleClickExportar = () => {
        actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion: `Se exporto el pedido de ${proveedorSelect}`  })
    }

    

    const handleChangeInput = (e) => {
        setPiezas(e.target.value)
    }

    const handleKey = (e) => {
        if(e.keyCode === 13){
            agregarItem(
                {
                    codigo: parseInt(e.target.dataset.codigo), 
                    nombre:e.target.dataset.nombre, 
                    pedido: piezas - e.target.placeholder,
                    cantidadInput: piezas
                }
            )
            e.target.value= ''
        }
        
    }
    
    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBarHeader open={open} setOpen={setOpen}/>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
            <Divider />
            
                <Menu open={open}/>

            </Drawer>
            <Box component="main" style={{width: '100%'}}>
                <Container>
                    <div className="botonera-header">
                        <div className="selectores">
                            <FormControl sx={{ minWidth: 220 }} size="small">
                                <InputLabel htmlFor="grouped-native-select">Pedidos Generados</InputLabel>
                                <Select 
                                    native defaultValue="" 
                                    id="grouped-native-select" 
                                    label="Pedidos Generados" 
                                    className="select-group" 
                                    onChange={handleChangePedidosGenerados}
                                    onClick={handleClickSelect}
                                >
                                    <option aria-label="None" value="" />
                                    {
                                        pedidos.map((item) => <option key={item} value={item}>{item}</option>)
                                    }
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 220 }} size="small">
                                <InputLabel id="demo-select-small">Filtrar Proveedor</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={proveedorSelect}
                                    label="Filtrar Proveedor"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                    <em>Todos los proveedores</em>
                                    </MenuItem>
                                    {
                                        proveedoresiltrados.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                            <IconButton variant="outlined" aria-label="delete" onClick={limpiar} className="iconbutton" size='small'>
                                <DeleteIcon />
                            </IconButton>

                            <IconButton variant="outlined" aria-label="delete" onClick={printData}  className="iconbutton" size='small'>
                                <PrintIcon />
                            </IconButton>
                            {
                                proveedorSelect === 'CEDIFA'
                                ?
                                <IconButton variant="outlined" aria-label="delete" onClick={handleSend}  className="iconbutton" size='small'>
                                    <SendIcon />
                                </IconButton>
                                :''
                            }

                        </div>
                        <div className="botones-secundarios">
                            <Button variant="contained" component="label" onClick={handleClickSelect} onChange={(e) => {const file = e.target.files[0]; readExcel(file);}}>
                                <DescriptionIcon />
                                Cargar pedido
                                <input hidden accept=".xlsx" multiple type="file" />
                            </Button>
                            
                            <ExcelFile element={<button className='btnDownload btnpedidos' onClick={handleClickExportar}><a to='#'><SiMicrosoftexcel /> <span>Exportar Pedido</span> </a></button>} filename={'Ped - ' + proveedorSelect + ' - ' + Fecha.getDate() + '-' + mes + '-' + Fecha.getFullYear()} >
                                <ExcelSheet data={pedidoFinal} name="Pedido">
                                    <ExcelColumn label="Codigo" value="codigo"/>
                                    <ExcelColumn label="Pedido" value="pedido"/>
                                    <ExcelColumn label="Nombre" value="nombre"/>
                                    <ExcelColumn label="Precio" value="mejorprecio"/>
                                    <ExcelColumn label="Proveedor" value="proveedor"/>
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    </div>
                    <div className="data-info-table">
                        {
                            showSkeleton === true 
                            ? pedidoSeleccionado.length > 0 
                            ? 
                                <TablaFiltrarPedidos 
                                    userSucursal={userSucursal} 
                                    pedidoFinal={pedidoFinal} 
                                    proveedorSelect={proveedorSelect}
                                    agregarItem={agregarItem}
                                    borrarItem={borrarItem}
                                    removerItem={removerItem}
                                    handleChangeInput={handleChangeInput}
                                    handleKey={handleKey}
                                />
                            : <SkeletonTablaRevisarPedidos/> 
                            : ''
                        }

                        

                        
                    </div>
                </Container>
            </Box>
        </Box>
    )
}