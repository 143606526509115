import { useContext } from 'react';
import Button from '@mui/material/Button';
import { CardContainer, CardHeader, ItemDepartamento, ImgContainer, CardBody, GroupTextCard, FooterCard, ControllerContainer } from './../elements/CardElement';
import { ContextoCarrito } from '../context/cartContext';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';

export function Card({codigo, nombre, existencia, precio, departamento, handleClickOpen}){

    const { addProductoCart, removeProductCart, productosCarrito } = useContext(ContextoCarrito);
    const imagenDefault = (e) =>{
        e.target.src =  'https://farmaprontoneza.com/image/predeterminada.jpg' 
    }

    let agregado = productosCarrito.find((item) => parseInt(item.codigo) === parseInt(codigo));
    console.log(productosCarrito)

    return(
        <CardContainer>
            <CardHeader>
                <ItemDepartamento>{departamento}</ItemDepartamento>
                {/* <div className="item-oferta"><FcApproval /></div> */}
            </CardHeader>
            
            <ImgContainer>
                <img loading="lazy" onError={imagenDefault} src={'https://farmaprontoneza.com/image/' + parseInt(codigo, 10) + '.jpg'} alt={nombre} />
            </ImgContainer>
            <CardBody>
                <GroupTextCard>
                    <p className="item-codigo">{codigo}</p>
                    <p className="item-nombre">{nombre}</p>
                </GroupTextCard>
                
                <GroupTextCard>
                    <p className="item-existencias">Existencia: <strong>{existencia}</strong> {existencia > 1 ? 'Pzs' : 'Pza'}</p>
                    <p className="item-precio">${precio}</p>
                </GroupTextCard>
            </CardBody>
            <FooterCard>
                {
                    agregado
                    ?
                    <ControllerContainer>
                        <div>
                            <IconButton aria-label="increase" onClick={() => removeProductCart({codigo: parseInt(codigo), nombre, pedido: 1, precio, existencia, disminuir: 1})}>
                                <RemoveIcon fontSize="small" />
                            </IconButton>
                            <span>{agregado ? agregado.pedido: 0} pzs</span>
                            <IconButton aria-label="increase" onClick={() => addProductoCart({codigo: parseInt(codigo), nombre, pedido: 1, precio, existencia})}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </ControllerContainer>
                    :
                    <Button className="btnAgregar" variant="contained" size="small" fullWidth
                        onClick={() => addProductoCart({codigo: parseInt(codigo), nombre, pedido: 1, precio, existencia})}
                    >
                        Agregar
                    </Button>
                }
            </FooterCard>
        </CardContainer>
    )
}