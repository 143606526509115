import { useEffect, useState } from 'react';
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from '../firebase/firebaseConfig';
import { getUnixTime, startOfDay, endOfDay } from 'date-fns';

const useObtenerActividades = () => {
    const [actividades, setActividades] = useState([]);

    const inicioDelDia = getUnixTime(startOfDay(new Date()));
    const finDelDia = getUnixTime(endOfDay(new Date()));
   
    useEffect(() => {
         console.log(inicioDelDia, finDelDia)

         const consultarDocumentos = () => {

             const consultarPedido = query(
                 collection(db, 'actividad'),
                 where('fechaActividad', '>=', inicioDelDia),
                 where('fechaActividad', '<=', finDelDia),
                 orderBy('fechaActividad', 'desc')
             );

            const unsuscribe = onSnapshot(
                consultarPedido,
                (querySnapshot) => {
                    setActividades(querySnapshot.docs.map((documento) => {
                        return { ...documento.data()}
                    }))
                }
            );
            return unsuscribe
         }
         consultarDocumentos();
    }, [inicioDelDia, finDelDia])

    return actividades;    
}

export default useObtenerActividades;