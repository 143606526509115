import { db } from './firebaseConfig';
import { doc, setDoc  } from "firebase/firestore";

const cargarBloqueados = async (productos) => {



    const data = await productos.map(({codigo, nombre, existencia}) => {
        return (
            {
                codigo: codigo,
                nombre: nombre,
                existencia: existencia
               
            }
        )
    });

    await setDoc(doc(db, "CatalogoBloqueados", 'bloqueados'), {
        catalogo: productos
      });
}

export default cargarBloqueados;