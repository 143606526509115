import { db } from './firebaseConfig';
import { doc, setDoc, deleteDoc  } from "firebase/firestore";

const cargarCatalogo = async (pedido) => {

    await deleteDoc(doc(db, "Catalogo", 'AlmacenVirtual'));

    const data = await pedido.map(({codigo, precio, existencia, almacen}) => {
        return (
            {
                codigo: codigo,
                precio: precio,
                existencia: existencia,
                almacen: almacen
               
            }
        )
    });



    console.log(data);

    await setDoc(doc(db, "Catalogo", 'AlmacenVirtual'), {
        catalogo: pedido
      });
}

export default cargarCatalogo;