import React,{ useState, useEffect } from 'react';
import { getComprasRequest, getMasterDePreciosRequest } from '../api/apiFarmaprontoCentro';
import { Container } from './../elements/Elementos';
import { ColumnaHeader, ColumnaTabla, FilaTabla, Tabla, HeaderTablaPop } from '../elements/TablaElements';
import formatoMoneda from '../functions/formatMoneda';
import { useAlmacenVirtual } from "../context/almacenVirtualContext";
import { useAuth } from '../context/AuthContext';
import { getUnixTime } from 'date-fns';
import actividadEnLaAplicacion from "../firebase/actividadEnLaAplicacion";

import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';
// Mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

// Tabla
// const columns = [
//     { id: 'sucursal', label: 'Sucursal', minWidth: 170 },
//     { id: 'cantidad', label: 'Recibidos', minWidth: 100 },
//     {
//       id: 'preciounitario',
//       label: 'P. Unitario',
//       minWidth: 170,
//       align: 'right',
//       format: (value) => formatoMoneda(value),
//     },
//     {
//       id: 'preciocompra',
//       label: 'P. Neto',
//       minWidth: 170,
//       align: 'right',
//       format: (value) => formatoMoneda(value),
//     },
//     {
//       id: 'proveedor',
//       label: 'Proveedor',
//       minWidth: 170
//     },
//     {
//       id: 'fecha',
//       label: 'Fecha',
//       minWidth: 170,
//       align: 'right',
//       format: (value) => value.replace('T00:00:00.000Z', ''),
//     }
//   ];
  
//   function createData(name, code, population, size) {
//     const density = population / size;
//     return { name, code, population, size, density };
//   }
  
export function MasterDePrecios(){
    const [codigoBuscado, setcodigoBuscado] = useState('');
    const [codigoChange, setCodigoChange] = useState('');
    const [arrayPreciosMayoristas, setArrayPreciosMayoristas] = useState([]);
    const [compras, setCompras] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { almacenVirtual } = useAlmacenVirtual();
    const { usuario } = useAuth();

    let userActividad = usuario.displayName;
    let fechaActividad = getUnixTime(new Date())
    const modulo = 'Master de Precios'
    const accion = 'Busqueda en master de precios' + codigoBuscado

    const connectApi = async () => {
        const res = await getMasterDePreciosRequest(codigoBuscado);
        setArrayPreciosMayoristas(res.data);
    }

    const getCompras = async () => {
        const res = await getComprasRequest(codigoBuscado);
        setCompras(res.data);
    }

    useEffect(() => {
        connectApi();
        getCompras();
    }, [codigoBuscado])

    const handleChangeInput = (e) => {
        setCodigoChange(e.target.value)
    }
    const handleKeyDown = (e) => {
        if(e.keyCode === 13){
            setcodigoBuscado(e.target.value)
            actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion})
        }
    }

    let arrayInfoMaster = [];

    if(arrayPreciosMayoristas.length > 0){
        arrayPreciosMayoristas.forEach((item) => {
            const buscarProductoEnAlmacenesVirtuales = almacenVirtual.find((item) => parseInt(item.codigo) === parseInt(codigoBuscado))
            const addArrayMaster = {
                codigo: item.codigo,
                nombre: item.nombre,
                iva: item.iva,
                margen: item.margen,
                precio: item.precio,
                oferta: item.oferta,
                nadro: item.nadro,
                marzam: item.marzam,
                farmacos: item.farmacos,
            }
            if(buscarProductoEnAlmacenesVirtuales){
                addArrayMaster.almacen = buscarProductoEnAlmacenesVirtuales.precio
            }else{
                addArrayMaster.almacen = 0
            }
            arrayInfoMaster.push(addArrayMaster)
        })        
    }else{
        arrayInfoMaster.push([])               
    }

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const imagenDefault = (e) =>{
        e.target.src =  'https://farmaprontoneza.com/image/predeterminada.jpg' 
    }

    return(
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarHeader open={open} setOpen={setOpen}/>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
            <Menu open={open}/>
        </Drawer>
        <Box component="main" style={{width: '100%'}}>
            <Container>
                <input 
                    type="search" 
                    className="inputcodigo" 
                    placeholder="Buscar producto por codigo" 
                    onKeyDown={handleKeyDown} 
                    onChange={handleChangeInput}
                />
                <div className='wrap-tablas'>
                    {
                        arrayInfoMaster.map(({codigo, nombre, precio, oferta}) => {
                            return(
                                <div className='info-datos'>
                                    <img loading="lazy" onError={imagenDefault} src={'https://farmaprontoneza.com/image/' + parseInt(codigo, 10) + '.jpg'} alt={nombre} />
                                    <div  className='data-result'>
                                        <p>Código: {codigo}</p>
                                        <p>Nombre: {nombre}</p>
                                        <p>Precio Venta: {formatoMoneda(precio ? precio : 0)} | Oferta: {formatoMoneda(oferta ? oferta : 0)}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <Tabla size='50%' margin='0px 20px'>
                        <caption>
                            <Tabla size='auto' margin='0px'>
                                <HeaderTablaPop bgColorLight>
                                    <ColumnaHeader bgColorLight>Proveedor</ColumnaHeader>
                                    <ColumnaHeader bgColorLight>Precio Unitario</ColumnaHeader>
                                    <ColumnaHeader bgColorLight>IVA</ColumnaHeader>
                                    <ColumnaHeader bgColorLight>Precio Neto</ColumnaHeader>
                                    <ColumnaHeader bgColorLight>Margen %</ColumnaHeader>
                                    <ColumnaHeader bgColorLight>Precio Sugerido</ColumnaHeader>
                                </HeaderTablaPop>
                                <tbody>
                                    {
                                        arrayInfoMaster.map(({nadro, marzam, farmacos, almacen, iva, margen}) => {
                                            return(
                                                <>
                                                    <FilaTabla bgColorLight>
                                                        <ColumnaTabla>Nadro</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(nadro)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{iva === true ? '16%' : '0%'}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? nadro*1.16 : nadro)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{margen}%</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? (nadro*1.16)*((margen/100)+1) : (nadro)*((margen/100)+1))}</ColumnaTabla>
                                                    </FilaTabla>
                                                    <FilaTabla bgColorLight>
                                                        <ColumnaTabla>Marzam</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(marzam)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{iva === true ? '16%' : '0%'}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? marzam*1.16 : marzam)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{margen}%</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? (marzam*1.16)*((margen/100)+1) : (marzam)*((margen/100)+1))}</ColumnaTabla>
                                                    </FilaTabla>
                                                    <FilaTabla bgColorLight>
                                                        <ColumnaTabla>Farmacos</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(farmacos)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{iva === true ? '16%' : '0%'}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? farmacos*1.16 : farmacos)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{margen}%</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? (farmacos*1.16)*((margen/100)+1) : (farmacos)*((margen/100)+1))}</ColumnaTabla>
                                                    </FilaTabla>
                                                    <FilaTabla bgColorLight>
                                                        <ColumnaTabla>Almacenes Virtuales</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? almacen/1.16 : almacen)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{iva === true ? '16%' : '0%'}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(almacen)}</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{margen}%</ColumnaTabla>
                                                        <ColumnaTabla align='center'>{formatoMoneda(iva === true ? (almacen*1.16)*((margen/100)+1) : (almacen)*((margen/100)+1))}</ColumnaTabla>
                                                    </FilaTabla>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>

                            </Tabla>
                        </caption>
                        <HeaderTablaPop bgColorLight>
                            <tr>
                                <ColumnaHeader bgColorLight>Sucursal</ColumnaHeader>
                                <ColumnaHeader bgColorLight>Recibidos</ColumnaHeader>
                                <ColumnaHeader bgColorLight>P. Unitario</ColumnaHeader>
                                <ColumnaHeader bgColorLight>P. Neto</ColumnaHeader>
                                <ColumnaHeader bgColorLight>Proveedor</ColumnaHeader>
                                <ColumnaHeader bgColorLight>Fecha</ColumnaHeader>
                            </tr>
                        </HeaderTablaPop>
                        <tbody>
                            {
                                compras.map(({codigo, cantidad, fecha, proveedor, sucursal, preciounitario, preciocompra}) => {
                                    return(
                                        <FilaTabla bgColorLight key={fecha, sucursal}>
                                            <ColumnaTabla>{sucursal.replace('0 -','').replace('1 -', '').replace('3 -','').replace('4 -', '').replace('5 -','').replace('6 -', '').replace('7 -','').replace('8 -', '').replace('9 -','').replace('10 -', '')}</ColumnaTabla>
                                            <ColumnaTabla align='center'>{cantidad}</ColumnaTabla>
                                            <ColumnaTabla align='center'>{formatoMoneda(preciounitario)}</ColumnaTabla>
                                            <ColumnaTabla align='center'>{formatoMoneda(preciocompra)}</ColumnaTabla>
                                            <ColumnaTabla>{proveedor}</ColumnaTabla>
                                            <ColumnaTabla>{fecha.replace('T00:00:00.000Z', '')}</ColumnaTabla>
                                        </FilaTabla>
                                    )
                                })
                            }
                        </tbody>
                    </Tabla>
                </div>
            </Container>
        </Box>
      </Box>
            

    )
}