import { useEffect, useState } from 'react';
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from '../firebase/firebaseConfig';
import { useAuth } from '../context/AuthContext';

const useObtenerPedidos = ({dateInitial, dateFinal}) => {
    const { usuario } = useAuth();
    const [misPedidos, setMisPedidos] = useState([]);

    console.log(misPedidos)
   
    useEffect(() => {
        const user = usuario.displayName;
         const inicioDelDia = dateInitial;
         const finDelDia = dateFinal;

         console.log(user, inicioDelDia, finDelDia)

         const consultarDocumentos = () => {

             const consultarPedido = query(
                 collection(db, 'pedidoscedifa'),
                 where('sucursal', '==', user),
                 where('fechaPedido', '>=', inicioDelDia),
                 where('fechaPedido', '<=', finDelDia),
                 orderBy('fechaPedido', 'desc')
             );

             const allPedido = query(
                collection(db, 'pedidoscedifa'),
                where('fechaPedido', '>=', inicioDelDia),
                where('fechaPedido', '<=', finDelDia),
                orderBy('fechaPedido', 'desc')
            );

            const unsuscribe = onSnapshot(
                usuario.displayName == 'CEDIFA' || usuario.displayName == 'IVAN' ? allPedido : consultarPedido,
                (querySnapshot) => {
                    setMisPedidos(querySnapshot.docs.map((documento) => {
                        return { ...documento.data()}
                    }))
                }
            );
            return unsuscribe
         }
         consultarDocumentos();
    }, [dateInitial, dateFinal])

    return misPedidos;    
}

export default useObtenerPedidos;