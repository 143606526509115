import styled from 'styled-components';

const Tabla = styled.table`
    border-collapse: collapse;
    margin: ${props => props.margin ? props.margin : "10px"};
    width: ${props => props.size ? props.size : "100%"};
    position: relative;
`;

const HeaderTabla = styled.thead`
    background-color: ${(props) => {
        if(props.bgColorPrincipal){
            return '#4338ca';
        }else if(props.bgColorLight){
          return 'white';
        }
    }};
    position: sticky;
    top: 141px;
`;

const HeaderTablaPop = styled.thead`
    background-color: ${(props) => {
        if(props.bgColorPrincipal){
            return '#4338ca';
        }else if(props.bgColorLight){
          return '#e4e4e4';
        }
    }};
`;

const ColumnaHeader = styled.th`
    color: ${(props) => {
        if(props.bgColorPrincipal){
            return 'white';
        }else if(props.bgColorLight){
        return '#494949';
        }
    }};
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    width: ${props => props.size ? props.size : "auto"};
`;


const FilaTabla = styled.tr`
    :nth-child(odd){
        background-color: ${(props) => {
            if(props.bgColorPrincipal){
                return '#dfdede';
            }else if(props.bgColorLight){
              return '#f1f1f1';
            }
        }};
    }
    :hover{
        background-color: #cdf9f9;
    }
    background-color: ${(props) => {
        if(props.color === 'pocaexistencia'){
            return '#fca0a0 !important';
        }
    }}
`;

const ColumnaTabla = styled.td`
    font-size: ${props => props.size ? props.size : "11px"};
    padding: 4px;
    text-align: ${props => props.align ? props.align : "left"};
    display: ${props => props.flex ? 'flex' : ''};
    button{
        background: transparent;
        border: none;
        border-radius: 2px;
        padding: 1px 8px;
    }   
    button svg{
        color: #3f3844;
        font-size: 18px;
    }
    button:hover{
        svg{
            color: #e10c0c;
        }
    }
`;

const BotonesTable = styled.button`
    background-color: #1976d2;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
`;

export { Tabla, HeaderTabla, ColumnaHeader, FilaTabla, ColumnaTabla, BotonesTable, HeaderTablaPop };