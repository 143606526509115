import React,{ useEffect, useState } from "react";
import { useAuth } from './../context/AuthContext';
import { getTraspasosRequest, getFacturasCedifaRequest } from "../api/apiFarmaprontoCentro";
import { Container } from './../elements/Elementos';
import { VscCheck, VscChromeClose } from 'react-icons/vsc';

import PrintIcon from '@mui/icons-material/Print';
import formatoMoneda from "../functions/formatMoneda";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';
// Mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
    // Tabla
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


function compare(a, b) {
    // Comparamos la propiedad familia
    if (a.familia < b.familia) return -1;
    if (a.familia> b.familia) return 1;
    else {         
      if (a.nombre > b.nombre) return 1;
      else if (a.nombre < b.nombre) return -1;
      return 0;
    }
}
  
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" align="left">{row.folio}</TableCell>
                <TableCell align="left">{row.usuario}</TableCell>
                <TableCell align="center">{row.articulos} {row.articulos > 1 ? ' Pzs' : ' Pza'}</TableCell>
                <TableCell align="center">{row.piezas} {row.piezas > 1 ? ' Pzs' : ' Pza'}</TableCell>
                <TableCell align="center">{formatoMoneda(row.importe)}</TableCell>
                <TableCell align="center">{row.recepcion}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={printData}>
                        <PrintIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow sx={{ '& > *': { borderBottom: 'unset'} }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0 }}>
                            <Table size="small" aria-label="purchases" id="mistraspasos">
                                <TableHead>
                                    <caption className="caption-mistraspasos">
                                        <table>
                                            <tr>
                                                <td>Folio:</td>
                                                <td>{row.folio}</td>
                                            </tr>
                                            <tr>
                                                <td>Importe:</td>
                                                <td>{formatoMoneda(row.importe)}</td>
                                            </tr>
                                        </table>
                                    </caption>
                                    <TableRow>
                                        <TableCell align="center">Codigo</TableCell>
                                        <TableCell align="center">Cantidad</TableCell>
                                        <TableCell align="center">Nombre</TableCell>
                                        <TableCell align="center">Precio</TableCell>
                                        <TableCell align="center">Precio Maximo</TableCell>
                                        <TableCell align="center">Estatus</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.productos.sort(compare).map((historyRow) => (
                                        <TableRow key={historyRow.codigo}>
                                            <TableCell component="th" scope="row"  align="right" style={{fontSize: '12px'}}>{historyRow.codigo}</TableCell>
                                            <TableCell  align="center" style={{fontSize: '12px'}}>{historyRow.cantidad}</TableCell>
                                            <TableCell style={{fontSize: '12px'}}>{historyRow.nombre}</TableCell>
                                            <TableCell align="right" style={{fontSize: '12px'}}>{formatoMoneda(historyRow.precio)}</TableCell>
                                            <TableCell align="right" style={{fontSize: '12px'}}>{formatoMoneda(historyRow.preciomaximo)}</TableCell>
                                            <TableCell align="right" style={{fontSize: '12px'}}>{historyRow.recepcion === 'PENDIENTE' ? <VscChromeClose /> : <VscCheck />}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.shape({
      trf: PropTypes.string.isRequired,
      usuario: PropTypes.number.isRequired,
      importe: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ).isRequired
    }).isRequired,
  };
  

const printData =() =>{
    var divToPrint=document.getElementById('mistraspasos');
    if(divToPrint){
        let newWin= window.open('', 'PRINT', 'height=800,width=1200');
        newWin.document.write(`
            <style>
                table{
                    width: auto; 
                    font-size: 10px;
                    font-family: Arial;
                }
                table > tbody td{
                    border: 1px solid black;
                    font-size: 10px;
                }
                table tbody td:nth-child(1){
                    width: 45px;
                    text-align: right;
                }
                table tbody td:nth-child(2){
                    width: 35px;
                    text-align: center;
                }
                table tbody td:nth-child(3){
                    width: 200px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                table tbody td:nth-child(6){
                    display: none;
                }
                table thead tr th:nth-child(6){
                    display: none;
                }
                



            </style>
        `);
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    }else{
        // toast.error("Oops!", "No hay nada que imprimir!", "error");
    }        
}

export function MisTraspasos(){
    const [traspaso, setTraspaso] = useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [traspasoSeleccionado, setTraspasoSeleccionado] = useState('')
    const [fecha, setFecha] = React.useState(dayjs(new Date()));
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const { usuario } = useAuth();

    let numeroSucursal = 0
    let tipoDeTransaccion = 'Traspaso'
    let tituloModulo = 'Mis Traspasos'
    if(usuario.displayName === 'CARMELO'){
        numeroSucursal = 3
    }else if(usuario.displayName === 'ESTADO'){
        numeroSucursal = 4
    }else if(usuario.displayName === 'ROMERO'){
        numeroSucursal = 6
    }else if(usuario.displayName === 'MAYOREO 2'){
        numeroSucursal = 5
    }else if(usuario.displayName === 'ECATEPEC'){
        numeroSucursal = 8
    }else if(usuario.displayName === 'MARAVILLAS'){
        numeroSucursal = 1234561
        tipoDeTransaccion = 'Factura'
        tituloModulo = 'Mis Facturas'
    }else if(usuario.displayName === 'LIDO'){
        numeroSucursal = 1234567
        tipoDeTransaccion = 'Factura'
        tituloModulo = 'Mis Facturas'
    }else if(usuario.displayName === 'BASILICA'){
        numeroSucursal = 1234510
        tipoDeTransaccion = 'Factura'
        tituloModulo = 'Mis Facturas'
    }else if(usuario.displayName === 'LOS REYES'){
        numeroSucursal = 1234569
        tipoDeTransaccion = 'Factura'
        tituloModulo = 'Mis Facturas'
    }
    
    let fechaSelect = fecha.$y + "-" + (fecha.$M+1) + "-" + fecha.$D

    const getTraspasos = async() =>{
        const res = tipoDeTransaccion === 'Traspaso' ? await getTraspasosRequest({numeroSucursal, fechaSelect}) : await getFacturasCedifaRequest({numeroSucursal, fechaSelect})
        setTraspaso(res.data)
    }
    
    useEffect(() => {
      getTraspasos()
    }, [usuario, fechaSelect])




    const datosTraspasos = traspaso.map((x) => x.folio)
    const arraySoloTraspasos = [... new Set(datosTraspasos)]
    let arrayTraspasos = [];

    arraySoloTraspasos.forEach((itemT) => {
        const buscarDatos = traspaso.find((item) => item.folio === itemT)

        const addArrayTraspasos = {
            folio: itemT
        }
        if(buscarDatos){
            addArrayTraspasos.usuario = buscarDatos.usuario;
            addArrayTraspasos.fecha = buscarDatos.fecha;
            addArrayTraspasos.productos = traspaso.filter((x) => x.folio === itemT);
            addArrayTraspasos.importe = traspaso.filter((x) => x.folio === itemT).reduce((a,b) => a+ b.precio * b.cantidad,0);
            addArrayTraspasos.piezas = traspaso.filter((x) => x.folio === itemT).reduce((a,b) => a+ b.cantidad,0);
            addArrayTraspasos.articulos = traspaso.filter((x) => x.folio === itemT).length;
            addArrayTraspasos.recepcion = buscarDatos.recepcion;           
        }

        arrayTraspasos.push(addArrayTraspasos)

    })

    const handleClickOpen = (folio) => {
        setTraspasoSeleccionado(folio)
        setOpenModal(true);
    };
    const handleClose = () => {
        setOpenModal(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    console.log(numeroSucursal, traspaso)
    
    return(
        <>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBarHeader open={open} setOpen={setOpen}/>
            <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
                <Menu open={open}/>
            </Drawer>
            <Box component="main" style={{width: '100%'}}>
                <Container>
                    <h1 className="titulo-modulo">{tituloModulo}</h1>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                            sx={{m:'10px 30px'}}
                            label="Fecha Pedido"
                            value={fecha}
                            onChange={(newValue) => setFecha(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">Traspaso</TableCell>
                                    <TableCell align="center">Usuario</TableCell>
                                    <TableCell align="center">Articuos</TableCell>
                                    <TableCell align="center">Piezas</TableCell>
                                    <TableCell align="center">Importe</TableCell>
                                    <TableCell align="center">Estatus</TableCell>
                                    <TableCell align="center">Print</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {arrayTraspasos.map((row) => (
                                <Row key={row.folio} row={row} />
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Box>
        </Box>
            
        </>
    )
}