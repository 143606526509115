import React, { useState, createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { getExistenciasRequest }from './../api/apiCedifa';



export const ExistenciasContext = createContext()

export const useExistencias = () =>{
    const context = useContext(ExistenciasContext)
    return context
}

export const ExistenciasProvider = ({children}) => {
    const [existencias, setExistencias] = useState([]);
    let location = useLocation();
    console.log(location.pathname)

    const getExistencias = async () => {
        const res = await getExistenciasRequest()
        setExistencias(res.data)
    }

    useEffect(() => {
            getExistencias()
      }, [location.pathname]);



    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         getExistencias()
    //     }, 500);
    //     return () => clearInterval(interval);
    //   }, []);
    
    function onlyUnique(value, index, self) { 
        return self.indexOf(value) === index;
    }

    const getFamilias = existencias.map(data => data.familia);
    const familias = [... new Set(getFamilias)];

    let arrayMenu = []

    familias.forEach((familia) => {
        const buscarFamilias = existencias.filter((item) => item.familia === familia)
        const addFamilias = {
            familia: familia
        }
        if(buscarFamilias){
            addFamilias.departamentos =  buscarFamilias.map(item => item.departamento).filter(onlyUnique).map(departamento => departamento)
            
        }
        arrayMenu.push(addFamilias)
    })

    return <ExistenciasContext.Provider value={{existencias, arrayMenu}}>
        {children}
    </ExistenciasContext.Provider>
}