import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import IconButton from '@mui/material/IconButton';
import { getNuevasEntradasRequest } from '../api/apiCedifa';
import { ColumnaHeader, ColumnaTabla, FilaTabla, HeaderTablaPop, Tabla } from '../elements/TablaElements';
import { ContextoCarrito } from '../context/cartContext';
import formatoMoneda from '../functions/formatMoneda';
import { isSunday } from 'date-fns'

export function PopUpNuevos({open, setOpen}){
  const [productos, setProductos] = useState([]);

  const { addProductoCart, removeProductCart, productosCarrito } = useContext(ContextoCarrito);

  const handleClose = () => {
    setOpen(false);
  };

  function sumarDias(fecha, dias){
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }
  let d = new Date();
  let dia = isSunday(new Date()) === true ? sumarDias(d, -1).toLocaleDateString().replace('/','-').replace('/','-') : new Date().toLocaleDateString().replace('/','-').replace('/','-')
  
  const getProductos = async () => {
    const res = await getNuevasEntradasRequest(dia)
    setProductos(res.data)
  }
  useEffect(() => {
    getProductos();
  },[open])

    return(
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth='md'
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">
          {"Ultmias entradas en almacen CEDIFA fecha: " + dia}
        </DialogTitle>
        <DialogContent>
          <Tabla size='100%' margin='0px'>
            <HeaderTablaPop bgColorPrincipal>
              <tr>
                <ColumnaHeader bgColorPrincipal>Código</ColumnaHeader>
                <ColumnaHeader bgColorPrincipal>Cantidad</ColumnaHeader>
                <ColumnaHeader bgColorPrincipal>Nombre</ColumnaHeader>
                <ColumnaHeader bgColorPrincipal>Precio</ColumnaHeader>
                <ColumnaHeader bgColorPrincipal>Agregar</ColumnaHeader>
              </tr>
            </HeaderTablaPop>
            <tbody>
              {
                productos &&
                productos.map(({codigo, piezas, nombre, precio}) => {
                  return(
                    <FilaTabla bgColorPrincipal>
                        <ColumnaTabla align='right'>{codigo}</ColumnaTabla>
                        <ColumnaTabla align='center'>{piezas}</ColumnaTabla>
                        <ColumnaTabla>{nombre}</ColumnaTabla>
                        <ColumnaTabla align='right'>{formatoMoneda(precio)}</ColumnaTabla>
                        <ColumnaTabla align='center'>
                        <IconButton color="primary" aria-label="add to shopping cart"
                          onClick={() => addProductoCart({codigo: parseInt(codigo), nombre, pedido: 1, precio, existencia: piezas})}
                        >
                          <AddShoppingCartIcon />
                        </IconButton>
                        </ColumnaTabla>
                    </FilaTabla>
                  )
                })
              }
            </tbody>
          </Tabla>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    )
}