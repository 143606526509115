// Importamos funcion para inicializar firebase
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Objeto de configuración
const firebaseConfig = {
    apiKey: "AIzaSyDeCiUujz8iEjfbHpepucXzTiD-BBr0GsI",
    authDomain: "farmaadmin.firebaseapp.com",
    projectId: "farmaadmin",
    storageBucket: "farmaadmin.appspot.com",
    messagingSenderId: "657057686541",
    appId: "1:657057686541:web:905e01fadbae15e9752144",
    measurementId: "G-Y79YJ9B1TD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

export {auth, db};