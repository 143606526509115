import { Button } from '@mui/material';
import { set } from 'date-fns';
import { useEffect, useState } from 'react';
import { getProductosParaBloquearRequest } from '../api/apiCedifa';
import { MenuPrincipal } from "../components/MenuPrincipal";
import { ToastContainer, toast } from 'react-toastify';
import { ColumnaHeader, ColumnaTabla, FilaTabla, Tabla, HeaderTablaPop } from '../elements/TablaElements';
import cargarCatalogoBloqueados from "../firebase/cargarBloqueados";

export function Bloqueados(){
    const [codigoBuscado, setcodigoBuscado] = useState('');
    const [codigoChange, setCodigoChange] = useState('');
    const [productos, setProductos] = useState([])
    const [productosBloqueados, setProductosBloqueados] = useState([])


    const getProductosParaBloquear = async () => {
        const res = await getProductosParaBloquearRequest(codigoBuscado)
        setProductos(res.data)
    }

    useEffect(() => {
        getProductosParaBloquear()
    }, [codigoBuscado])
    

    const handleChangeInput = (e) => {
        setCodigoChange(e.target.value)
    }
    const handleKeyDown = (e) => {
        if(e.keyCode === 13){
            setcodigoBuscado(e.target.value)
        }
    }

    const addProductoBloqueado = ({codigo, nombre, existencia}) => {
        setProductosBloqueados([...productosBloqueados, {codigo, nombre, existencia}])
    }

    const handleSend = (e) =>{
        cargarCatalogoBloqueados(productosBloqueados)
        setProductosBloqueados([])
        toast.success('Se subio satisfactoriamente!', {
            position: "bottom-right",
            autoClose: 1600,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "dark",
        });          
    }

    return(
        <>
            <MenuPrincipal />
            <div className="container">
                <input type="search" className="inputcodigo" placeholder="Buscar producto por codigo" onKeyDown={handleKeyDown} onChange={handleChangeInput}/>
                
                <div className='container-tablas'>
                    <Tabla size='40%' margin='20px'>
                        <HeaderTablaPop bgColorPrincipal>
                            <ColumnaHeader bgColorPrincipal>Código</ColumnaHeader>
                            <ColumnaHeader bgColorPrincipal>Nombre</ColumnaHeader>
                            <ColumnaHeader bgColorPrincipal>Existencia</ColumnaHeader>
                            <ColumnaHeader bgColorPrincipal></ColumnaHeader>
                        </HeaderTablaPop>
                        <tbody>
                            {
                                productos.map(({codigo, nombre, existencia}) => {
                                    return(
                                        <FilaTabla key={codigo} bgColorPrincipal>
                                            <ColumnaTabla align='right'>{codigo}</ColumnaTabla>
                                            <ColumnaTabla>{nombre}</ColumnaTabla>
                                            <ColumnaTabla align='center'>{existencia}</ColumnaTabla>
                                            <ColumnaTabla>
                                                <Button variant="contained" onClick={() => addProductoBloqueado({codigo: parseInt(codigo), nombre, existencia })}>
                                                    Agregar
                                                </Button>
                                            </ColumnaTabla>
                                        </FilaTabla>
                                    )
                                })
                            }
                        </tbody>
                    </Tabla>
                    <div>
                        <Button variant='contained' onClick={() => setProductosBloqueados([])}>Limpiar</Button>
                        <Button variant='contained' onClick={handleSend}>Bloquear</Button>
                        {
                            productosBloqueados.map(({codigo, nombre, existencia}) => {
                                return(
                                    <p>Codigo: {codigo} | Nombre: {nombre} | Existencia: {existencia}</p>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}