import React,{ useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { ContextoCarrito } from '../context/cartContext';

// Mui
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { Carrito } from './Carrito';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
      right: -4,
      top: 12,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
  },
  }));




export function AppBarHeader({open, setOpen}){
  const [busqueda, setBusqueda] = useState('');  
  const [state, setState] = React.useState({right: false,});
  let navigate = useNavigate();

  const { countCart } = useContext(ContextoCarrito);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleChangeBusqueda = (event) => {
    setBusqueda(event.target.value);
  }

  const handleEnter = (event) => {
    setBusqueda(event.target.value);
    if(event.keyCode == 13){
      navigate('/busqueda/'+ busqueda);
    }
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
};

    return(
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              FarmaAdmin 
            </Typography>
            <div>
              <input 
                  type='search'
                  placeholder="Buscar producto..." 
                  onChange={handleChangeBusqueda}
                  onKeyDown={handleEnter}
                  className='input-search'
              />
            </div>
            <div className='cart-icon'>
              <IconButton aria-label="cart" onClick={toggleDrawer('right', true)}>
                  <StyledBadge badgeContent={countCart} color="primary">
                      <ShoppingCartIcon className="icon-cart"/>
                  </StyledBadge>
              </IconButton>
            </div>
          </Toolbar>
          <Carrito state={state} toggleDrawer={toggleDrawer}/>
        </AppBar>
    )
}