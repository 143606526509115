import React, { useState, useEffect, useContext } from 'react';
import { collection, onSnapshot,  query} from "firebase/firestore";
import { db } from '../firebase/firebaseConfig';

const ContextoAlmacen = React.createContext();

export const useAlmacenVirtual = () =>{
    const context = useContext(ContextoAlmacen)
    return context
}

const AlmacenProvider = ({children}) => {
    const [catalogoAlmacen, setCatalogoAlmacen] = useState([]);


    useEffect(() => {
        const consultarDocumento = () => {
            const consultarCatalogo = query(
                collection(db, 'Catalogo')
            );
            const unsuscribe = onSnapshot(
                consultarCatalogo,
                (QuerySnapshot) => {
                    setCatalogoAlmacen(QuerySnapshot.docs.map((documento) => {
                        return { ...documento.data()}
                    }))
                }
            );
            return unsuscribe
        }
        consultarDocumento();
    },[])

    let almacenVirtual = [];

    const recorrerCatalogo = catalogoAlmacen.map(({catalogo}) => {
        return(
            catalogo.map(({codigo, precio, almacen, existencia}) =>{
                return(
                    almacenVirtual.push({codigo: codigo, precio: precio, almacen: almacen, existencia})
                )
            })
        )
    }); 

    return(
        <ContextoAlmacen.Provider value={{
            almacenVirtual
        }}>
            {children}
        </ContextoAlmacen.Provider>
    )
}

export { ContextoAlmacen, AlmacenProvider };