import React,{ useEffect, useState, useContext } from "react";
import { getDesplazamientosPorSucursalRequest, getReporteCerosRequest } from "../api/apiFarmaprontoCentro";
import { useAuth } from './../context/AuthContext';
import { Container } from './../elements/Elementos';
import { useExistencias } from '../context/existenciasContext';
import useObtenerPedidos from '../hooks/useObtenerPedidos';
import { ColumnaHeader, ColumnaTabla, FilaTabla, HeaderTablaPop, Tabla } from '../elements/TablaElements';
import { ContextoCarrito } from '../context/cartContext';
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ReactExport from "react-export-excel";
import { SiMicrosoftexcel } from 'react-icons/si';
import actividadEnLaAplicacion from "../firebase/actividadEnLaAplicacion";
import { getUnixTime, startOfDay, endOfDay } from 'date-fns';
import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';
// Mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function ReporteCeros(){
    const [desplazamientos, setDesplazamientos] = useState([]);
    const [ceros, setCeros] = useState([]);
    const {usuario} = useAuth();
    const { existencias } = useExistencias();
    const dateInitial = getUnixTime(startOfDay(new Date()));
    const dateFinal = getUnixTime(endOfDay(new Date()));
    const misPedidos  = useObtenerPedidos({dateInitial, dateFinal});
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    let userActividad = usuario.displayName
    let fechaActividad = getUnixTime(new Date())
    const modulo = 'Reporte ceros'
    const accion = 'Descargo el reporte de ceros en excel'

    const { addProductoCart } = useContext(ContextoCarrito);


    let numeroSucursal = 0

    if(usuario.displayName === 'MARAVILLAS'){
        numeroSucursal = 1
    }else if(usuario.displayName === 'CARMELO'){
        numeroSucursal = 3
    }else if(usuario.displayName === 'ESTADO'){
        numeroSucursal = 4
    }else if(usuario.displayName === 'MAYOREO 2'){
        numeroSucursal = 5
    }else if(usuario.displayName === 'ROMERO'){
        numeroSucursal = 6
    }else if(usuario.displayName === 'LIDO'){
        numeroSucursal = 7
    }else if(usuario.displayName === 'ECATEPEC'){
        numeroSucursal = 8
    }else if(usuario.displayName === 'LOS REYES'){
        numeroSucursal = 9
    }else if(usuario.displayName === 'BASILICA'){
        numeroSucursal = 10
    }
    

    const getDesplazamientos = async () => {
        const res = await getDesplazamientosPorSucursalRequest(numeroSucursal)
        setDesplazamientos(res.data)
    }

    const getReporteCeros = async () => {
        const res = await getReporteCerosRequest(usuario.displayName)
        setCeros(res.data)
    }

    useEffect(() => {
        getDesplazamientos();
        getReporteCeros();
    }, [])

    const datosPedido = misPedidos.map((x) => x.idPedido);
    const arrayIdPedidos = [...new Set(datosPedido)]


    let arrayCollecionPedidos = [];

    arrayIdPedidos.forEach((x) => {
        const buscarPedidos = misPedidos.find((item) => item.idPedido === x)
        const addInArraycoleccionPedidos = {
            id: x,
        }
        if(buscarPedidos){
            addInArraycoleccionPedidos.sucursal = buscarPedidos.sucursal
        }else{
            addInArraycoleccionPedidos.sucursal = 0
        }
        if(buscarPedidos){
            addInArraycoleccionPedidos.fecha = buscarPedidos.fechaPedido
        }else{
            addInArraycoleccionPedidos.fecha = 0
        }

        arrayCollecionPedidos.push(addInArraycoleccionPedidos)

    })

    let arrayPedidos = []

    const recorrerPedidos = misPedidos.map(({sucursal, productos}) => {
        return(
            productos.map(({codigo, pedido, nombre, precio, cedifa}) => {
                return(
                    arrayPedidos.push({codigo: codigo, pedido: pedido, nombre, precio, sucursal: sucursal, cedifa: cedifa})
                )
            })
        )
    })


    let arrayReporteCeros = []

    desplazamientos.forEach((item) => {
        const buscarExistenciasEnSucursal = ceros.find((data) => data.codigo === item.codigo)
        const buscarExistenciaEnCedifa = existencias.find((data) => data.codigo === item.codigo)
        const buscarEnPedidos = arrayPedidos.find((data) => parseInt(data.codigo) === parseInt(item.codigo))
        const addArrayReporteCeros = {
            codigo: item.codigo,
            nombre: item.articulo,
            desplazamiento: item.desplazamiento,
            familia: item.familia,
            departamento: item.departamento
        }

        if(buscarExistenciasEnSucursal){
            addArrayReporteCeros.existencia = buscarExistenciasEnSucursal.existencias
        }else{
            addArrayReporteCeros.existencia = 'ELIMINAR'
        }

        if(buscarExistenciaEnCedifa){
            addArrayReporteCeros.cedifa = buscarExistenciaEnCedifa.existencia;
            addArrayReporteCeros.precio = buscarExistenciaEnCedifa.precio
        }else{
            addArrayReporteCeros.cedifa = 0;
            addArrayReporteCeros.precio = 0
        }
        if(buscarEnPedidos){
            addArrayReporteCeros.pedido = buscarEnPedidos.pedido
        }else{
            addArrayReporteCeros.pedido = 0
        }

        arrayReporteCeros.push(addArrayReporteCeros);
    })

    const sortArray = (x, y) =>{
        if(x.desplazamiento > y.desplazamiento) {return -1;}
        if(x.desplazamiento < y.desplazamiento) {return  1;}
        return 0;
    }

    let arrayFilterReporte = arrayReporteCeros.filter((item) => item.existencia != 'ELIMINAR');

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const handleClick = () =>{
        actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion })
    }

    const handleDrawerClose = () => {
        setOpen(false);
      };

    return(
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarHeader open={open} setOpen={setOpen}/>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
            <Menu open={open}/>
        </Drawer>
        <Box component="main" style={{width: '100%'}}>
            <Container>
                <ExcelFile element={<button className='btnDownload btnReporte' onClick={handleClick}><a to='#'><SiMicrosoftexcel /> <span>Exportar</span> </a></button>} filename={'Reportede Ceros'} >
                    <ExcelSheet data={arrayFilterReporte} name="Cragar Pedido">
                        <ExcelColumn label="Codigo" value="codigo"/>
                        <ExcelColumn label="Nombre" value="nombre"/>
                        <ExcelColumn label="Existencia" value="existencia"/>
                        <ExcelColumn label="Desplazamiento" value="desplazamiento"/>
                        <ExcelColumn label="Cedifa" value="cedifa"/>
                        <ExcelColumn label="Familia" value="familia"/>
                        <ExcelColumn label="Departamento" value="departamento"/>
                    </ExcelSheet>
                </ExcelFile>

                <Tabla size='auto' margin='30px 30px'>
                    <HeaderTablaPop bgColorLight>
                        <tr>
                            <ColumnaHeader bgColorLight>Código</ColumnaHeader>
                            <ColumnaHeader bgColorLight>Nombre</ColumnaHeader>
                            <ColumnaHeader bgColorLight>Existencia</ColumnaHeader>
                            <ColumnaHeader bgColorLight>30 Días</ColumnaHeader>
                            <ColumnaHeader bgColorLight>Cedifa</ColumnaHeader>
                            <ColumnaHeader bgColorLight>Pedidos</ColumnaHeader>
                            <ColumnaHeader bgColorLight>Familia</ColumnaHeader>
                            <ColumnaHeader bgColorLight>Departamento</ColumnaHeader>
                            <ColumnaHeader bgColorLight>Agregar</ColumnaHeader>
                        </tr>
                    </HeaderTablaPop>
                    <tbody>
                        {
                            ceros &&
                            arrayFilterReporte.sort(sortArray).map(({codigo, nombre, existencia, desplazamiento, cedifa, pedido, familia, departamento, precio}) =>{
                                return(
                                    <FilaTabla bgColorLight key={codigo}>
                                        <ColumnaTabla align="right">{codigo}</ColumnaTabla>
                                        <ColumnaTabla>{nombre}</ColumnaTabla>
                                        <ColumnaTabla align="center">{existencia}</ColumnaTabla>
                                        <ColumnaTabla align="center">{desplazamiento}</ColumnaTabla>
                                        <ColumnaTabla  align="center">{cedifa}</ColumnaTabla>
                                        <ColumnaTabla  align="center">{pedido}</ColumnaTabla>
                                        <ColumnaTabla>{familia}</ColumnaTabla>
                                        <ColumnaTabla>{departamento}</ColumnaTabla>
                                        <ColumnaTabla>
                                        {
                                            cedifa > 0
                                            ?
                                            <IconButton color="primary" aria-label="add to shopping cart"
                                            onClick={() => addProductoCart({codigo: parseInt(codigo), nombre, pedido: 1, precio, existencia: cedifa})}
                                            >
                                                <AddShoppingCartIcon />
                                            </IconButton>
                                            : ''
                                        }
                                        
                                        </ColumnaTabla>                                        
                                    </FilaTabla>
                                )
                            })
                        }
                    </tbody>
                </Tabla>
            </Container>
        </Box>
      </Box>
            
    )
}