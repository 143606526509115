import styled from 'styled-components';

const Container = styled.div`
    background-color: white;
    width: 100%;
    margin-top: 60px;
    min-height: 100vh;
`;



export { Container };