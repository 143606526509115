import useObtenerTodosLosPedidos from '../hooks/useObtenerTodosLosPedidos';
import { MenuPrincipal } from './../components/MenuPrincipal';
import { ColumnaHeader, ColumnaTabla, FilaTabla, HeaderTabla, HeaderTablaPop, Tabla } from './../elements/TablaElements';
import { db } from '../firebase/firebaseConfig';
import { doc, deleteDoc } from "firebase/firestore";


export function BorrarMisPedidos () {

    const pedidos = useObtenerTodosLosPedidos()

    

    const datos = pedidos.map((x) => x.idPedido)
    const arrayIdPedidos = [...new Set(datos)]

    let arrayPedidos = [];

    arrayIdPedidos.forEach((ped) => {
        const buscarDatos = pedidos.find((item) => item.idPedido === ped)
        const addInArrayPedidos = {
            idPedido: ped
        }
        if(buscarDatos){
            addInArrayPedidos.fecha = buscarDatos.fechaPedido
        }else{
            addInArrayPedidos.fecha = 0
        }
        arrayPedidos.push(addInArrayPedidos);
    })


    const handleClick = async (idPedido) => {
        try {
            await deleteDoc(doc(db, 'pedidoscedifa',idPedido))
            // Swal.fire(
            //     'Correcto!',
            //     'Se elimino correctamente!!',
            //     'success'
            //   )
        } catch (error) {
            console.log(error)
        }
        console.log(idPedido)
    }


    return(
        <>
            <MenuPrincipal />
            <Tabla size='30%' margin='30px'>
                <HeaderTablaPop bgColorPrincipal>
                    <ColumnaHeader bgColorPrincipal>Id Pedido</ColumnaHeader>
                    <ColumnaHeader bgColorPrincipal>Fecha Unix</ColumnaHeader>
                    <ColumnaHeader bgColorPrincipal>Fecha</ColumnaHeader>
                    <ColumnaHeader bgColorPrincipal>Eliminar</ColumnaHeader>
                </HeaderTablaPop>
                <tbody>
                    {
                        arrayPedidos.map(({idPedido, fecha}) => {
                            return(
                                <FilaTabla bgColorPrincipal>
                                    <ColumnaTabla>{idPedido}</ColumnaTabla>
                                    <ColumnaTabla>{fecha}</ColumnaTabla>
                                    <ColumnaTabla>{new Date(fecha * 1000).toLocaleDateString()} {new Date(fecha * 1000).toLocaleTimeString()}</ColumnaTabla>
                                    <ColumnaTabla><button onClick={() => handleClick(idPedido)} >Eliminar</button></ColumnaTabla>
                                </FilaTabla>
                            )
                        })
                    }
                </tbody>
            </Tabla>
        </>
    )
}