import { useEffect, useState } from 'react';
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from '../firebase/firebaseConfig';
import { useAuth } from '../context/AuthContext';

const useObtenerTodosLosPedidos = () => {
    const [misPedidos, setMisPedidos] = useState([]);
   
    useEffect(() => {
         const consultarDocumentos = () => {

             const consultarPedido = query(
                 collection(db, 'pedidoscedifa'),
                 orderBy('fechaPedido', 'desc')
             );


            const unsuscribe = onSnapshot(
                consultarPedido,
                (querySnapshot) => {
                    setMisPedidos(querySnapshot.docs.map((documento) => {
                        return { ...documento.data()}
                    }))
                }
            );
            return unsuscribe
         }
         consultarDocumentos();
    }, [])

    return misPedidos;    
}

export default useObtenerTodosLosPedidos;