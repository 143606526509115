import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContextoCarrito = React.createContext();

const CarritoProvider = ({children}) =>{
    const [productosCarrito, setProductosCarrito] = useState([]);
    const [click, setClick] = useState(false);



  useEffect(() => {
    let data = localStorage.getItem('CarritoCedifa');
    console.log('LocalStorage:', data)
    // setProductosCarrito(JSON.parse(data));
    if(data != null){
      setProductosCarrito(JSON.parse(data));
    }else{
      setProductosCarrito([]);
    }
  },[click])

    //Agregar producto al carrito
    const addProductoCart = ({codigo, nombre, pedido, precio, cantidad}) => {
        if(!productosCarrito.find(product => product.codigo === codigo)){
            setProductosCarrito([...productosCarrito, {codigo: codigo, nombre: nombre, pedido: pedido, precio, importe: precio * pedido, cedifa: cantidad}]);
            localStorage.setItem('CarritoCedifa', JSON.stringify([...productosCarrito, {codigo: codigo, nombre: nombre, pedido: pedido, precio, importe: precio * pedido, cedifa: cantidad}]))
            setClick(!click)
            toast.success('Se Agrego 1 pieza', {
                position: "top-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: 0,
                theme: "light",
            });
        }else{
            setProductosCarrito(productosCarrito.map(p => (p.codigo === codigo ? {...p, pedido: p.pedido + 1, importe : p.precio * (p.pedido + 1) } : p)))
            toast.success('Se Agrego 1 pieza', {
                position: "top-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: 0,
                theme: "light",
            });
        }
    }

    const removeProductCart = ({codigo, disminuir, agregados}) => {
        if(productosCarrito.find(product => product.codigo === codigo && disminuir == 1)){
            setProductosCarrito(productosCarrito.map(p => (p.codigo === codigo ? {...p, pedido: p.pedido - 1, importe: p.precio * (p.pedido - 1)} : p)))
            toast.success('Se Quito 1 pieza', {
                position: "top-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: 0,
                theme: "light",
            });
        }
        if(productosCarrito.find(product => product.codigo === codigo && disminuir == 1 && agregados == 1)){
          let newProductItem = productosCarrito.filter( x => x.codigo != codigo);
          setProductosCarrito(newProductItem);

        }
    }

    const deleteProductoCart = (codigo) => {
        let newCarrito = productosCarrito.filter( x => x.codigo != codigo);
        setProductosCarrito(newCarrito);
    }
    //Vaciar carrito
    const vaciarCarrito = () => {
        localStorage.removeItem('CarritoCedifa');
        setProductosCarrito([]);
    }

    let countCart = productosCarrito.reduce((a,b) => a + b.pedido, 0)
    let importeCart = productosCarrito.reduce((a,b) => a + b.importe, 0)


    return(
        <ContextoCarrito.Provider value={{
            productosCarrito, 
            addProductoCart, 
            removeProductCart, 
            deleteProductoCart, 
            vaciarCarrito, 
            countCart,
            importeCart
        }}>
            {children}
        </ContextoCarrito.Provider>
    )

}


export { ContextoCarrito, CarritoProvider };
