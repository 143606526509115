import axios from 'axios';

const URLSERVER = 'https://appi-rest-farmacentro.herokuapp.com/api/'
// const URLSERVER = 'http://localhost:3002/api/'

export const getPedidosGeneradosRequest = async (userSucursal) => await axios.get(URLSERVER + 'pedidos-generados/' + userSucursal)
export const getPreciosMayoristasRequest = async () => await axios.get(URLSERVER + 'precios-mayoristas/')
export const getMasterDePreciosRequest = async (parametro) => await axios.get(URLSERVER + 'master/' + parametro)
export const getDesplazamientosPorSucursalRequest = async (parametro) => await axios.get(URLSERVER + 'desplazamientos-por-sucursal/' + parametro)
export const getComprasRequest = async (parametro) => await axios.get(URLSERVER + 'compras/' + parametro)
export const getDesplazamientosRequest = async (parametro) => await axios.get(URLSERVER + 'desplazamientos/' + parametro)
export const getBuscarProductosRequest = async (parametro) => await axios.get(URLSERVER + 'buscar-productos/' + parametro)
export const getTraspasosRequest = async({numeroSucursal, fechaSelect}) => await axios.get(URLSERVER + 'traspasos/' + numeroSucursal + '/fecha/' + fechaSelect)
export const getFacturasCedifaRequest = async({numeroSucursal, fechaSelect}) => await axios.get(URLSERVER + 'facturascedifa/' + numeroSucursal + '/fecha/' + fechaSelect)
export const getDashboardComprasRequest = async() => await axios.get(URLSERVER + 'dashboard-compras/')

export const getReporteCerosRequest = async (parametro) => await axios.get(URLSERVER + 'reporte-ceros/' + parametro)
export const getExistenciasGlobalesRequest = async (parametro) => await axios.get(URLSERVER + 'existencias/' + parametro)

