import React,{ useState, useEffect } from 'react';
import { useParams } from "react-router";
import { Card } from './../components/Card';
import ReactPaginateProps from 'react-paginate';
import { getExistenciasPorFamiliaRequest } from "../api/apiCedifa";
import { MenuPrincipal } from "../components/MenuPrincipal";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { FcApproval } from 'react-icons/fc';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export function Productos(){
    const [existencias, setExistencias] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectProducto, setSelectProducto] = useState('');

    let {departamento} = useParams();

    const refreshSite =()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
    }


    const getExistencias = async () => {
        const res = await getExistenciasPorFamiliaRequest(departamento)
        setExistencias(res.data)
    }

    useEffect(() => {
        getExistencias();
        refreshSite();
        setPageNumber(0)
    },[departamento])

    const handleClickOpen = (codigo) => {
        setOpen(true);
        setSelectProducto(codigo)
    };
    const handleClose = () => {
        setOpen(false);
    };

    const usersPerPage = 120
    const pagesVisited = pageNumber * usersPerPage   
    const displayUsers = existencias
      .slice(pagesVisited, pagesVisited + usersPerPage)
      .map(({codigo, nombre, precio, existencia, departamento, reservado, agregados}) => {
        return(
          <Card 
            key={codigo}
            codigo={codigo}
            nombre={nombre}
            precio={precio}
            existencia={existencia}
            departamento={departamento}
            reservado={reservado}
            agregados={agregados}
            handleClickOpen={handleClickOpen}
          />
        );
      });
  
    const pageCount = Math.ceil(existencias.length / usersPerPage);
    
    const changePage = ({selected}) => {
        setPageNumber(selected);
        refreshSite();
    }

    const imagenDefault = (e) =>{
        e.target.src =  'https://farmaprontoneza.com/image/predeterminada.jpg' 
    }

    const productoViewPopUp = selectProducto === '' ? [] : existencias.find((item) => item.codigo === selectProducto);

    return(
        <>
            <MenuPrincipal />
            <div className="cotainer">
                    <div className="container-cards">
                        {displayUsers}
                    </div>
                    <ReactPaginateProps 
                            previousLabel={"Anterior"}
                            nextLabel={"Siguiente"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previusBtn"}
                            nextLinkClassName={"nextBtn"}
                            disabledClassName={"paginatioDisabled"}
                            activeClassName={"paginationActive"}
                        />
                </div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <div className="card card-pop">
                            <div className="header-card">
                                <div className="item-departamento">{productoViewPopUp.departamento}</div>
                                <div className="item-oferta"><FcApproval /></div>
                            </div>
                            <div className="imagen">
                                <img loading="lazy" onError={imagenDefault} src={'https://farmaprontoneza.com/image/' + parseInt(selectProducto, 10) + '.jpg'} alt={productoViewPopUp.codigo} />
                            </div>
                            <div className="info-card">
                                <span className="item-codigo">{productoViewPopUp.codigo}</span>
                                <p className="item-nombre">{productoViewPopUp.nombre}</p>
                                <span className="items-precio-existencias">
                                    <span className="item-existencias">Existencia: {productoViewPopUp.existencia}</span>
                                    <span className="item-precio">${productoViewPopUp.precio}</span>
                                </span>
                                <span>Apartados: 10</span>
                            </div>
                            <div className="footer-card">
                                <Button className="btnAgregar" variant="contained" size="small" fullWidth>
                                    Agregar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </>
    )
}