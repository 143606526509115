import React,{ useState } from "react";
import { Container }from './../elements/Elementos';
import { useExistencias } from '../context/existenciasContext';
import { Card } from './../components/Card';
import ReactPaginateProps from 'react-paginate';
import { PopUpCard } from "../components/PopUpCard";
import { SkeletonCards } from "../components/SkeletonCards";
import ReactExport from "react-export-excel";
import { SiMicrosoftexcel } from 'react-icons/si';
import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';


// Mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function Home (){
    // States
    const { existencias} = useExistencias();
    const [pageNumber, setPageNumber] = useState(0);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const handleClickOpen = (codigo) => {
        setOpen(true);
    };

    // Paginacion
    const usersPerPage = 200
    const pagesVisited = pageNumber * usersPerPage
    
    const displayUsers = existencias
      .slice(pagesVisited, pagesVisited + usersPerPage)
      .map(({codigo, nombre, precio, existencia, departamento, reservado, agregados}) => {
        return(
          <Card 
            key={codigo}
            codigo={codigo}
            nombre={nombre}
            precio={precio}
            existencia={existencia}
            departamento={departamento}
            reservado={reservado}
            agregados={agregados}
            handleClickOpen={handleClickOpen}
          />
        );
      });
  
    const pageCount = Math.ceil(existencias.length / usersPerPage);
    
    const changePage = ({selected}) => {
        setPageNumber(selected);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
    }

    return(
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarHeader open={open} setOpen={setOpen}/>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          
              <Menu open={open}/>

        </Drawer>
        <Box component="main">
          <Container>
            <div className="container-cards">
              {
                existencias.length === 0 ? <SkeletonCards /> : displayUsers
              }
            </div>
            <ReactPaginateProps 
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previusBtn"}
              nextLinkClassName={"nextBtn"}
              disabledClassName={"paginatioDisabled"}
              activeClassName={"paginationActive"}
            />
          </Container>
        </Box>
      </Box>
    )
}