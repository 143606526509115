import { ColumnaHeader, ColumnaTabla, FilaTabla, HeaderTabla, Tabla } from '../elements/TablaElements';
import DeleteIcon from '@mui/icons-material/Delete';
import formatoMoneda from '../functions/formatMoneda';
import ordenarPorNombre from '../functions/ordenarPorNombre';


export function TablaFiltrarPedidos({userSucursal, pedidoFinal, proveedorSelect, agregarItem, borrarItem, removerItem, handleChangeInput, handleKey}){
    return(
        <Tabla size='90%' margin='0px' id='tablaRevisarPedido'>
            <caption className="caption-revisarpedido">
                Proveedor: {proveedorSelect}
            </caption>
            <HeaderTabla bgColorLight className='static-menu'>
                <tr>
                    <ColumnaHeader bgColorLight >Código</ColumnaHeader>
                    <ColumnaHeader bgColorLight >Pedido</ColumnaHeader>
                    <ColumnaHeader bgColorLight  width='200px'>Nombre</ColumnaHeader>
                    <ColumnaHeader bgColorLight >Cedifa</ColumnaHeader>
                    <ColumnaHeader bgColorLight >Nadro</ColumnaHeader>
                    <ColumnaHeader bgColorLight >Marzam</ColumnaHeader>

                    {/* <ColumnaHeader bgColorPrincipal>Farmacos</ColumnaHeader> */}
                    {
                        userSucursal === 'MAYOREO 2' || userSucursal === 'BASILICA'
                        ? <ColumnaHeader bgColorLight>Farmacos</ColumnaHeader>
                        : ''
                    }
                    
                    <ColumnaHeader bgColorLight>Almacen</ColumnaHeader> 
                    <ColumnaHeader bgColorLight className="precio-tabla">Precio</ColumnaHeader>
                    <ColumnaHeader bgColorLight>Proveedor</ColumnaHeader>
                    <ColumnaHeader bgColorLight></ColumnaHeader>
                </tr>
            </HeaderTabla>
            <tbody>
                {
                    pedidoFinal.sort(ordenarPorNombre).map(({codigo, pedido, nombre, cedifa, nadro, marzam, farmacos, almacenVirtual, mejorprecio, proveedor, unlocked})=> {
                        return(
                            <FilaTabla bgColorLight key={codigo} className={cedifa > 0 ? '' : unlocked}>
                                <ColumnaTabla size='11px' align='right'>{codigo}</ColumnaTabla>
                                <ColumnaTabla size='11px' align='center' flex='flex'>
                                    <input 
                                        type='text' 
                                        data-codigo={codigo}
                                        data-nombre={codigo}
                                        placeholder={pedido} 
                                        title='Cuantas piezas quieres sumar'
                                        onChange={handleChangeInput} 
                                        onKeyDown={handleKey}
                                        className='inputTabla'
                                    />
                                    {/* <div className="wrap-controller">
                                        <button className='controllerbutton' onClick={() => removerItem({codigo})}><span>-</span></button>
                                        <span className='piezas'>{pedido}</span>
                                        <button className='controllerbutton' onClick={() => agregarItem({codigo})}><span>+</span></button>
                                    </div> */}
                                </ColumnaTabla>
                                <ColumnaTabla size='11px' align='left'><span>{nombre}</span></ColumnaTabla>
                                <ColumnaTabla size='11px' align='center'>{cedifa}</ColumnaTabla>
                                <ColumnaTabla size='11px' align='right'>{formatoMoneda(nadro)}</ColumnaTabla>
                                <ColumnaTabla size='11px' align='right'>{formatoMoneda(marzam)}</ColumnaTabla>


                                {/* <ColumnaTabla size='11px' align='right'>{formatoMoneda(farmacos)}</ColumnaTabla> */}
                                {
                                    userSucursal === 'MAYOREO 2' || userSucursal === 'BASILICA'
                                    ? <ColumnaTabla size='11px' align='right'>{formatoMoneda(farmacos)}</ColumnaTabla>
                                    : ''
                                }
                                <ColumnaTabla size='11px' align='right'>{formatoMoneda(almacenVirtual)}</ColumnaTabla>
                                <ColumnaTabla size='11px' align='right' className="precio-tabla">{formatoMoneda(mejorprecio)}</ColumnaTabla>
                                <ColumnaTabla size='11px' align='left'>{proveedor}</ColumnaTabla>
                                <ColumnaTabla size='11px' align='center'><button className="deleteItem" onClick={() => borrarItem(codigo)} >{<DeleteIcon />}</button></ColumnaTabla>
                            </FilaTabla>
                        )
                    })
                }
                
            </tbody>
        </Tabla>
    )
}