import React,{ useState, useEffect } from "react";
import { MenuPrincipal } from "../components/MenuPrincipal";
import { useParams } from "react-router";
import { getBuscarProductosRequest } from "../api/apiCedifa";
import { Card } from "../components/Card";
import imgNotFound from './../assets/img/notfound.png';
import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';
import { Container } from './../elements/Elementos';
// Mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function Buscar(){
    const [productos, setProductos] = useState([]);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    let { buscar } = useParams();

    const getProductos = async () => {
        const res = await getBuscarProductosRequest(buscar)
        setProductos(res.data)
    }
    useEffect(() => {
        getProductos();
    },[buscar])  

    const handleDrawerClose = () => {
        setOpen(false);
      };

    return(
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarHeader open={open} setOpen={setOpen}/>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
            <Menu open={open}/>
        </Drawer>
        <Box component="main" style={{width: '100%'}}>
            <Container>
            <div className="container-cards">
                    {
                        productos.length  > 0
                        ?
                        productos.map(({codigo, nombre, precio, existencia, departamento}) => {
                            return(
                                <Card 
                                    key={codigo}
                                    codigo={codigo}
                                    nombre={nombre}
                                    precio={precio}
                                    existencia={existencia}
                                    departamento={departamento}
                                    // handleClickOpen={handleClickOpen}
                                />
                            )
                        })
                        : 
                        <>
                            <h3>No se tiene existencia</h3>
                            <img src={imgNotFound}/>
                        </>
                    }
                </div>

            </Container>
        </Box>
      </Box>
    )
}