import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export function SkeletonCards(){
    const cards = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
    return(
        <>
        {
            cards.map(() => {
                return(
                    <Stack spacing={2} className="card-skeleton">
                        {/* For variant="text", adjust the height via font-size */}
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        {/* For other variants, adjust the size with `width` and `height` */}
                        <Skeleton variant="circular" width={120} height={120}/>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="rectangular" width={200} height={100} />
                    </Stack>
                )
            })
        }
        </>
    )
}