import { useState } from "react";
import { MenuPrincipal } from "../components/MenuPrincipal";
import useObtenerActividades from "../hooks/useObtenerActividades";

export function Actividades(){
    const [moduloSelect, setModuloSelect] = useState('')
    const actividades = useObtenerActividades()

    const modulosAll = actividades.map((x) => x.modulo)
    const arrayModulos = [... new Set(modulosAll)]

    const sucursalesAll = actividades.map((x) => x.sucursal)
    const arraySucursal = [... new Set(sucursalesAll)]

    const handleClickSelect = (e)=> {
        setModuloSelect(e.target.value)
    }

    let arrayActividades = []
    arraySucursal.forEach((item) => {
        const buscarDatos = actividades.find((x) => x.sucursal === item)
        const addArrayActividades = {
            sucursal: item
        }
        if(buscarDatos){
            addArrayActividades.modulos = actividades.filter((x) => x.sucursal === item && x.modulo === moduloSelect)
        }

        arrayActividades.push(addArrayActividades)

    })

    console.log(arrayActividades)


    return(
        <>
            <MenuPrincipal />
            <div className="wrap">
                <select onClick={handleClickSelect}>
                    {
                        arrayModulos.map((data) =>{
                            return(
                                <option>{data}</option>
                            )
                        })
                    }
                </select>

                <div>
                    {
                        arrayActividades.map(({sucursal, modulos}) => {
                            return(
                                <>
                                    <ul>Sucursal: {sucursal} <span>Total de acciones{modulos.length}</span>
                                    {
                                        modulos.map(({accion}) => {
                                            return(
                                                <li>{accion}</li>
                                            )
                                        })
                                    }
                                    </ul>
                                </>
                                
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}