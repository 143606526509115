import React,{ useState, useContext } from 'react';
import { useAuth } from './../context/AuthContext';
import { signOut } from "firebase/auth";
import { auth } from './../firebase/firebaseConfig';
import { Link, useNavigate } from "react-router-dom";
import { VscHome, VscAccount, VscLayersActive, VscSignIn, VscGoToFile, VscGlobe, VscGraphLine, VscPreview, VscMegaphone, VscFilterFilled, VscOutput, VscReferences } from 'react-icons/vsc'


export function Menu({open}){
  const {usuario} = useAuth();
  let navigate = useNavigate();

  const logout = async () => {
    await signOut(auth);
    navigate('/')
  }

  return(
      <ul className="drawer-vertical" syle={{position: 'relative'}}>
        <li><Link to='#' style={{display: open === true ? 'flex' : 'block' }}><VscAccount /> <span style={{ opacity: open === true ? 1 : 0 }}>{usuario.displayName}</span></Link></li>
        <li><Link to='/home' style={{display: open === true ? 'flex' : 'block' }}><VscHome/> <span style={{ opacity: open === true ? 1 : 0 }}> Home</span></Link></li>
        <li><Link to='/revisar-pedido' style={{display: open === true ? 'flex' : 'block' }}><VscFilterFilled /> <span style={{ opacity: open === true ? 1 : 0 }}>Filtrar pedido</span></Link></li>
        <li><Link to='/mis-pedidos' style={{display: open === true ? 'flex' : 'block' }}><VscLayersActive /> <span style={{ opacity: open === true ? 1 : 0 }}>Mis pedidos</span></Link></li>
        <li><Link to='#' style={{display: open === true ? 'flex' : 'block' }}><VscReferences /> <span style={{ opacity: open === true ? 1 : 0 }}>Machear Pedido</span></Link></li>
        
        <li><Link to='/traspasos' style={{display: open === true ? 'flex' : 'block' }}><VscGoToFile /> <span style={{ opacity: open === true ? 1 : 0 }}>Mis traspasos</span></Link></li>
        <li><Link to='/existencias' style={{display: open === true ? 'flex' : 'block' }}><VscGlobe /> <span style={{ opacity: open === true ? 1 : 0 }}>Existencias Globales</span></Link></li>
        
        <li><Link to='/desplazamientos' style={{display: open === true ? 'flex' : 'block' }}><VscGraphLine /> <span style={{ opacity: open === true ? 1 : 0 }}>Desplazamientos</span></Link></li>
        <li><Link to='/master' style={{display: open === true ? 'flex' : 'block' }}><VscPreview /> <span style={{ opacity: open === true ? 1 : 0 }}>Master de precios</span></Link></li>
        <li><Link to='/reporte-ceros' style={{display: open === true ? 'flex' : 'block' }}><VscOutput /> <span style={{ opacity: open === true ? 1 : 0 }}>Reporte de ceros</span></Link></li>
        <li><Link to='#' style={{display: open === true ? 'flex' : 'block' }}><VscMegaphone /> <span style={{ opacity: open === true ? 1 : 0 }}>Nuevos</span></Link></li>


        <li><Link to='/' style={{display: open === true ? 'flex' : 'block', bottom: '0px', position: 'absolute' }} onClick={logout}><VscSignIn /> <span style={{ opacity: open === true ? 1 : 0 }}>Salir</span></Link></li>
      </ul>
  )
}