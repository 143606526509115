import React, { useState } from 'react';
import { getUnixTime, startOfDay, endOfDay } from 'date-fns';
import useObtenerPedidosSelect from '../hooks/useObtenerPedidoSelect';
import useObtenerPedidos from '../hooks/useObtenerPedidos';
import { useExistencias } from '../context/existenciasContext';
import { ColumnaHeader, ColumnaTabla, FilaTabla, HeaderTabla, Tabla, HeaderTablaPop } from '../elements/TablaElements';
import { Container } from './../elements/Elementos';
import { useAuth } from './../context/AuthContext';


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';
// Mui
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


export function MisPedidos(){
    const { usuario } = useAuth();
    const [datef, setDatef] = useState(new Date());
    const [openModal, setOpenModal] = React.useState(false);
    const [idPedidoSelect, setIdPedidoSelect] = useState('');
    const pedido = useObtenerPedidosSelect(idPedidoSelect);
    const [fecha, setFecha] = React.useState(dayjs(new Date()));
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [toggleState, setToggleState] = useState(0);
    const dateInitial = getUnixTime(startOfDay(fecha.$d));
    const dateFinal = getUnixTime(endOfDay(fecha.$d));
    const misPedidos  = useObtenerPedidos({dateInitial, dateFinal});
    const { existencias } = useExistencias();
    const [value, setValue] = React.useState(0);

    const handleClickOpen = (id) => {
        setOpenModal(true);
        setIdPedidoSelect(id)
        
      };
    
      const handleClose = () => {
        setOpenModal(false);
      };
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const datosPedido = misPedidos.map((x) => x.idPedido);
    const arrayIdPedidos = [...new Set(datosPedido)]


    let arrayCollecionPedidos = [];

    arrayIdPedidos.forEach((x) => {
        const buscarPedidos = misPedidos.find((item) => item.idPedido === x)
        const addInArraycoleccionPedidos = {
            id: x,
        }
        if(buscarPedidos){
            addInArraycoleccionPedidos.sucursal = buscarPedidos.sucursal
        }else{
            addInArraycoleccionPedidos.sucursal = 0
        }
        if(buscarPedidos){
            addInArraycoleccionPedidos.fecha = buscarPedidos.fechaPedido
        }else{
            addInArraycoleccionPedidos.fecha = 0
        }

        arrayCollecionPedidos.push(addInArraycoleccionPedidos)

    })



    let arrayPedidos = []

    const recorrerPedidos = misPedidos.map(({sucursal, productos}) => {
        return(
            productos.map(({codigo, pedido, nombre, precio, cedifa}) => {
                return(
                    arrayPedidos.push({codigo: codigo, pedido: pedido, nombre, precio, sucursal: sucursal, cedifa: cedifa})
                )
            })
        )
    })

     //obtener array de sucursales
     const sucursales = arrayPedidos.map((x) => x.sucursal);
     const arraySucursales = [...new Set(sucursales)]
 
     let arrayPedidosSucursales = [];
     
     arraySucursales.forEach((s) => {
         //filtrat unicamnete los pedidos por sucursal
         const filtro = arrayPedidos.filter((x) => x.sucursal === s)
 
         //obtener unicamente los codigo de la sucursal ya filtrada
         const codigos = filtro.map((x) => x.codigo)
         const arrayCodigos = [...new Set(codigos)]
 
         let arrayProductos = [];
 
         //recorrer codigos de cada pedido por sucursal filtrado
         arrayCodigos.forEach((cod) => {
             const filtroCod = filtro.filter((x) => x.codigo == cod)
             const piezas = filtroCod.reduce((acc, valor) => acc + valor.pedido, 0)
             const buscarExistencia = existencias.find((x) => parseInt(x.codigo,10) == cod)
             //array de pedido, unificando los codigos repetidos
             arrayProductos.push(
                 {
                     codigo: cod, 
                     pedido: piezas, 
                     nombre: filtroCod.find((item) => item.codigo === cod).nombre,
                     precio: filtroCod.find((item) => item.codigo === cod).precio,
                     familia: buscarExistencia ? buscarExistencia.familia == 'MEDICAMENTO' ? buscarExistencia.familia + ' - ' + buscarExistencia.departamento : buscarExistencia.familia : 'SIN FAMILIA',
                     // familia: buscarExistencia.familia === 'MEDICAMENTO' ? buscarExistencia.familia + ' - ' + buscarExistencia.departamento : buscarExistencia ? buscarExistencia.familia : '',
                     cedifa: filtroCod.find((item) => item.codigo === cod).cedifa ? filtroCod.find((item) => item.codigo === cod).cedifa : 0,
                     departamento : buscarExistencia ? buscarExistencia.departamento : '',
                     famili: buscarExistencia ? buscarExistencia.familia : '',
                 })
         })
 
         const sortArray = (x, y) =>{
             if(x.familia < y.familia) {return -1;}
             if(x.familia > y.familia) {return  1;}
             return 0;
         }
 
         function compare(a, b) {
             // Comparamos la propiedad familia
             if (a.familia < b.familia) return -1;
             if (a.familia> b.familia) return 1;
             else {         
               if (a.nombre > b.nombre) return 1;
               else if (a.nombre < b.nombre) return -1;
               return 0;
             }
         }
      
         let arrayPedido = arrayProductos.sort(compare);
         const filtrarPorSucursal = arrayCollecionPedidos.filter((x) => x.sucursal === s)
         //array de pedido de sucursal, indentado por sucursal y agregando el array del pedido generado
         const addInArrayPedidosSucursales = {
             sucursal: s,
             productos: arrayPedido,
             totalPedidos: filtrarPorSucursal
         }
 
         arrayPedidosSucursales.push(addInArrayPedidosSucursales);
    })

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const printData =() =>{
        var divToPrint=document.getElementById(value);
        if(divToPrint){
            let newWin= window.open("");
            newWin.document.write(`
                <style>
                    p{
                      font-size: 12px;
                      font-family: Arial;
                      line-height: 8px;
                    }
                    table{
                        width: auto; 
                        font-size: 10px;
                        font-family: Arial;
                    }
                    table tbody td{
                        border: 1px solid black;
                        font-size: 14px;
                    }
                    table tbody td:nth-child(1){
                        width: 45px;
                        text-align: right;
                    }
                    table tbody td:nth-child(2), td:nth-child(4){
                        width: 30px;
                        text-align: center;
                    }
                    table tbody td:nth-child(3){
                        width: 130px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    table tbody td:nth-child(5){
                        width: 90px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    table table{
                        width: 1200px;
                        text-align: left;
                    }
                    table table td{
                        border: 0 none;
                    }
                    table table td:nth-child(2){
                        width: 400px!important;
                        text-align: left;
                        border-bottom: 1px solid black;
                    }
                    table table td:nth-child(3){
                        width: 60px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    table table td:nth-child(4){
                        width: 600px!important;
                        text-align: left;
                        border-bottom: 1px solid black;
                    }
                    details{
                        display: none;
                    }
                    button{
                        display: none;
                    }
                </style>
            `);
            newWin.document.write(divToPrint.outerHTML);
            newWin.print();
            newWin.close();
        }else{
            // toast.error("Oops!", "No hay nada que imprimir!", "error");
        }        
    }
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return(
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarHeader open={open} setOpen={setOpen}/>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
            <Menu open={open}/>
        </Drawer>
        <Box component="main" style={{width: '100%'}}>
            <Container>
                <h1 className="titulo-modulo">Mis Pedidos</h1>
                <div className='cabecera-mis-pedidos'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                            sx={{m:'10px 30px'}}
                            label="Fecha Pedido"
                            value={fecha}
                            onChange={(newValue) => setFecha(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>

                <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {
                            arrayPedidosSucursales.map((data, index) => {
                                return(
                                    <Tab label={data.sucursal} {...a11yProps(index)} />
                                )
                            })
                        }
                    </Tabs>
                        {
                            arrayPedidosSucursales.map((data, index) => {
                                return(
                                    <TabPanel value={value} index={index}>
                                        <Button onClick={printData} variant='outlined' size='small'>IMPRIMIR {data.sucursal}</Button>
                                        <details>
                                            <summary>Ver mis pedidos</summary>
                                            <ul className='lista-pedidos'>
                                                {
                                                  data.totalPedidos.map(({id, fecha}) => {
                                                    return(
                                                        <li onClick={() => handleClickOpen(id)}>
                                                            <span>{id}</span>
                                                            <span>{new Date(fecha * 1000).toLocaleDateString()} {new Date(fecha * 1000).toLocaleTimeString()}</span>
                                                        </li>
                                                    )
                                                })  
                                                }
                                            </ul>                                            
                                        </details>
                                        <Tabla size='100%' margin='0px' id={value}>
                                            <caption className='info-tabla'>
                                                <div  className='caption-mis-pedidos'>
                                                    <Tabla  size='50%' margin='5px 0px' className='ifo-pedido-tabla'>
                                                        <FilaTabla>
                                                            <ColumnaTabla>Sucursal: </ColumnaTabla>
                                                            <ColumnaTabla>{data.sucursal}</ColumnaTabla>
                                                            <ColumnaTabla>Surtio: </ColumnaTabla>
                                                            <ColumnaTabla></ColumnaTabla>
                                                        </FilaTabla>

                                                        <FilaTabla>
                                                            <ColumnaTabla>Escaneo: </ColumnaTabla>
                                                            <ColumnaTabla></ColumnaTabla>
                                                            <ColumnaTabla>Id Envio: </ColumnaTabla>
                                                            <ColumnaTabla></ColumnaTabla>
                                                        </FilaTabla>
                                                        <FilaTabla>
                                                            <ColumnaTabla>Importe:</ColumnaTabla>
                                                            <ColumnaTabla>$ </ColumnaTabla>
                                                            <ColumnaTabla>Observaciones:</ColumnaTabla>
                                                            <ColumnaTabla> </ColumnaTabla>
                                                        </FilaTabla>
                                                    </Tabla>
                                                </div>
                                            </caption>
                                            <HeaderTablaPop bgColorLight>
                                                <ColumnaHeader bgColorLight >Código</ColumnaHeader>
                                                <ColumnaHeader bgColorLight >Pedido</ColumnaHeader>
                                                <ColumnaHeader bgColorLight >Nombre</ColumnaHeader>
                                                <ColumnaHeader bgColorLight >Cedifa</ColumnaHeader>
                                                <ColumnaHeader bgColorLight >Departamento</ColumnaHeader>
                                            </HeaderTablaPop>
                                            <tbody>                    
                                            {
                                                data.productos.map((data, index) => {
                                                    return(
                                                        <FilaTabla bgColorLight key={data.codigo}>
                                                            <ColumnaTabla align='right'>{data.codigo}</ColumnaTabla>
                                                            <ColumnaTabla align='center'>{data.pedido}</ColumnaTabla>
                                                            <ColumnaTabla>{data.nombre}</ColumnaTabla>
                                                            <ColumnaTabla align='center'>{data.cedifa}</ColumnaTabla>
                                                            <ColumnaTabla>{data.familia}</ColumnaTabla>
                                                        </FilaTabla>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </Tabla>
                                    </TabPanel>
                                )
                            })
                        }
                </Box>
                <Dialog 
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth='md'
                    maxWidth='md'
                >
                    <DialogTitle id="alert-dialog-title" className='titulo-modal'>
                        {
                            pedido.map(({idPedido, fechaPedido})=> {
                                return(
                                    <>
                                        <p>Pedido: {idPedido}</p>
                                        <p>Fecha: {new Date(fechaPedido * 1000).toLocaleDateString()} {new Date(fechaPedido * 1000).toLocaleTimeString()}</p>
                                    </>
                                )
                            })
                        }
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Tabla  size='95%' margin='0px 20px'>
                            <HeaderTablaPop bgColorLight>
                                <ColumnaHeader bgColorLight>Codigo</ColumnaHeader>
                                <ColumnaHeader bgColorLight>Pedido</ColumnaHeader>
                                <ColumnaHeader bgColorLight>Nombre</ColumnaHeader>
                            </HeaderTablaPop>
                            <tbody>
                                {
                                pedido.map(({productos}) => {
                                    return(
                                        productos.map(({codigo, pedido, nombre}) => {
                                            return(
                                                <FilaTabla bgColorLight>
                                                    <ColumnaTabla align='right'>{codigo}</ColumnaTabla>
                                                    <ColumnaTabla align='center'>{pedido}</ColumnaTabla>
                                                    <ColumnaTabla>{nombre}</ColumnaTabla>
                                                </FilaTabla>
                                            )
                                        })
                                    )
                                    })
                                }
                            </tbody>
                        </Tabla>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    {/* <Button onClick={handleClose} autoFocus>
                        Cerrar
                    </Button> */}
                    </DialogActions>
                </Dialog>

            </Container>
        </Box>
      </Box>


            
    )
}