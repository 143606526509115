import { MenuPrincipal } from "../components/MenuPrincipal";
import * as XLSX from 'xlsx';
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { ColumnaHeader, ColumnaTabla, FilaTabla, HeaderTablaPop, Tabla } from '../elements/TablaElements';
import { useAlmacenVirtual } from "../context/almacenVirtualContext";


import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import cargarCatalogo from "../firebase/cargarCatalogo";

export function CargarAlmacenesVirtuales(){

    const [pedido, setPedido] = useState([]);
    const { almacenVirtual } = useAlmacenVirtual();
    console.log(almacenVirtual)

    let totalPiezas = almacenVirtual.reduce((acc, val) => acc + parseInt(val.existencia), 0)

    const readExcel = (file) =>{
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = (e) => {
                const buffeArray = e.target.result;
                const wb = XLSX.read(buffeArray, {type: 'buffer'});
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data=XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error)
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 1600,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "dark",
                });
            };
        });

        promise.then((d) => {
            setPedido(d);
            toast.success('Se cargo el catalogo correctamente!', {
                position: "bottom-right",
                autoClose: 1600,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "dark",
            });
        });
    };

    const handleSend = (e) =>{
        cargarCatalogo(pedido)
        setPedido([])
        toast.success('Se subio satisfactoriamente!', {
            position: "bottom-right",
            autoClose: 1600,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "dark",
        });          
    }



    console.log(pedido)
    return (
        <>
            <MenuPrincipal />
            <div className="container">
                <div className="botonera">
                    <Button sx={{margin: 5}} variant="contained" component="label" onChange={(e) => {const file = e.target.files[0]; readExcel(file);}}>
                        <DescriptionIcon />
                        Importar Archivo
                        <input hidden accept=".xlsx" multiple type="file" />
                    </Button>

                    <Button sx={{margin: 5}} variant="contained" component="label" onClick={handleSend}>
                        Cargar catalogo
                    </Button>

                    <span>Total Articulos: {almacenVirtual ? almacenVirtual.length : 0} | Total piezas: {almacenVirtual ? totalPiezas : 0}</span>

                </div>

                <Tabla size="30%" margin="10px">
                    <HeaderTablaPop bgColorPrincipal>
                        <tr>
                            <ColumnaHeader bgColorPrincipal>Código</ColumnaHeader>
                            <ColumnaHeader bgColorPrincipal>Precio</ColumnaHeader>
                            <ColumnaHeader bgColorPrincipal>Existencia</ColumnaHeader>
                            <ColumnaHeader bgColorPrincipal>Almacen</ColumnaHeader>
                        </tr>
                    </HeaderTablaPop>
                    <tbody>
                        {
                            pedido.map(({codigo, existencia, precio, almacen})=>{
                                return(
                                    <FilaTabla bgColorPrincipal>
                                        <ColumnaTabla align="right">{codigo}</ColumnaTabla>
                                        <ColumnaTabla align="center">{precio}</ColumnaTabla>
                                        <ColumnaTabla align="center">{existencia}</ColumnaTabla>
                                        <ColumnaTabla>{almacen}</ColumnaTabla>
                                    </FilaTabla>
                                )
                            } )
                        }
                    </tbody>
                </Tabla>
                
            </div>
        </>

        
    )
}