import React, { useEffect, useState } from 'react';
import { getBuscarProductosRequest, getExistenciasGlobalesRequest } from '../api/apiFarmaprontoCentro';
import { Container } from './../elements/Elementos';
import { ColumnaHeader, ColumnaTabla, FilaTabla, Tabla, HeaderTablaPop } from '../elements/TablaElements';
import { FcFullBattery, FcLowBattery, FcEmptyBattery } from  'react-icons/fc'
import formatoMoneda from '../functions/formatMoneda';
import { useAuth } from './../context/AuthContext';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import actividadEnLaAplicacion from '../firebase/actividadEnLaAplicacion';
import { getUnixTime } from 'date-fns';

import { Menu } from './../components/Menu';
import { AppBarHeader } from '../components/AppBarHeader';
// Mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// Drawer Mui
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export function ExistenciasGlobales(){
    const [codigoBuscado, setcodigoBuscado] = useState('');
    const [codigoChange, setCodigoChange] = useState('');
    const [nombreBuscado, setNombreBuscado] = useState('')
    const [productos, setProductos] = useState([])
    const [datos, setDatos] = useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const {usuario} = useAuth();

    let userActividad = usuario.displayName;
    let fechaActividad = getUnixTime(new Date())
    const modulo = 'Existencias Globales'

    const handleClickOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };

    
    const getExistenciasGlobales = async () => {
        const res = await getExistenciasGlobalesRequest(codigoBuscado);
        setDatos(res.data)
    }

    const getBuscarProductos = async () => {
        const res = await getBuscarProductosRequest(nombreBuscado);
        setProductos(res.data)
    }

    useEffect(() => {
        getExistenciasGlobales()
    }, [codigoBuscado])

    useEffect(() => {
        getBuscarProductos()
    }, [nombreBuscado])
    
    const handleChangeInput = (e) => {
        setCodigoChange(e.target.value)
    }
    
    const handleKeyDown = (e) => {
        if(e.keyCode === 13){
            if(e.target.name === 'codigo'){
                setcodigoBuscado(e.target.value)
                actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion:`Realizo la busqueda por codigo: ${e.target.value}`  })
            }else if(e.target.name === 'nombre'){
                setNombreBuscado(e.target.value)
                setOpenModal(true);
                actividadEnLaAplicacion({userActividad, fechaActividad, modulo, accion:`Realizo la busqueda por nombre: ${e.target.value}`  })
            }
        }
    }

    const handleClick = ({codigo}) => {
        setcodigoBuscado(codigo);
        setOpenModal(false);
    }
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const imagenDefault = (e) =>{
        e.target.src =  'https://farmaprontoneza.com/image/predeterminada.jpg' 
    }
    return(
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarHeader open={open} setOpen={setOpen}/>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
            <Menu open={open}/>
        </Drawer>
        <Box component="main" style={{width: '100%'}}>
            <Container>
                <div className='botonera-general'>
                    <input type="search" name='codigo' className="inputcodigo" placeholder="Buscar producto por codigo" onChange={handleChangeInput} onKeyDown={handleKeyDown}/>
                    <input type="search" name='nombre' className="inputcodigo" placeholder="Buscar producto por nombre" onKeyDown={handleKeyDown}/>
                    <Button variant="outlined" onClick={handleClickOpenModal}>
                        Abrir Tabla
                    </Button>
                </div>
                {
                    datos.map(({codigo, nombre}) => {
                        return(
                            <div className='info-datos'>
                                <img loading="lazy" onError={imagenDefault} src={'https://farmaprontoneza.com/image/' + parseInt(codigo, 10) + '.jpg'} alt={nombre} />
                                <div className='data-result'>
                                    <p>Código: {codigo}</p>
                                    <p>Nombre: {nombre}</p>
                                    
                                    <ul className='nomenclatura'>
                                        <li><FcFullBattery /> Disponibilidad amplia</li>
                                        <li><FcLowBattery /> Disponibilidad baja</li>
                                        <li><FcEmptyBattery /> No disponible</li>
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }

                <Tabla size='200px' margin='0px 40px'>
                    <HeaderTablaPop bgColorPrincipal>
                        <ColumnaHeader bgColorPrincipal>Sucursal</ColumnaHeader>
                        <ColumnaHeader bgColorPrincipal>Estatus</ColumnaHeader>
                    </HeaderTablaPop>
                    <tbody>
                        {
                            datos.map(({maravillas, carmelo, estado, mayoreo2, romero, lido, ecatepec,reyes, basilica}) => {
                                return(
                                    <>
                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Maravillas</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ? maravillas
                                                    :maravillas > 0 
                                                    ? maravillas >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? maravillas <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Carmelo</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?carmelo
                                                    :carmelo > 0 
                                                    ? carmelo >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? carmelo <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Estado</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?estado
                                                    :estado > 0 
                                                    ? estado >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? estado <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Mayoreo II</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?mayoreo2
                                                    :mayoreo2 > 0 
                                                    ? mayoreo2 >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? mayoreo2 <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Romero</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?romero
                                                    :romero > 0 
                                                    ? romero >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? romero <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Lido</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?lido
                                                    :lido > 0 
                                                    ? lido >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? lido <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Ecatepec</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?ecatepec
                                                    :ecatepec > 0 
                                                    ? ecatepec >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? ecatepec <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Reyes</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?reyes
                                                    :reyes > 0 
                                                    ? reyes >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? reyes <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>

                                        <FilaTabla>
                                            <ColumnaTabla size='14px'>Basílica</ColumnaTabla>
                                            <ColumnaTabla align='center' className='columna-tabla'>
                                                {
                                                    usuario.displayName === 'CEDIFA' || usuario.displayName === 'IVAN'
                                                    ?basilica
                                                    :basilica > 0 
                                                    ? basilica >= 3 ? <FcFullBattery />: <FcEmptyBattery />
                                                    ? basilica <= 3 ? <FcLowBattery /> : <FcFullBattery />
                                                    : <FcEmptyBattery />
                                                    : <FcEmptyBattery />
                                                
                                                }
                                            </ColumnaTabla>
                                        </FilaTabla>
                                        
                                    </>
                                )
                            })
                        }
                    </tbody>
                </Tabla>
                <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth='md'
                    maxWidth='md'
                >
                    <DialogTitle id="alert-dialog-title">
                        Selecciona el producto a buscar
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Tabla  size='630px' margin='0px 20px'>
                            <HeaderTablaPop bgColorPrincipal>
                                <ColumnaHeader bgColorPrincipal>Codigo</ColumnaHeader>
                                <ColumnaHeader bgColorPrincipal>Nombre</ColumnaHeader>
                                <ColumnaHeader bgColorPrincipal>Precio</ColumnaHeader>
                            </HeaderTablaPop>
                            <tbody>
                                {   
                                    productos.map(({codigo, nombre, precio}) => {
                                        return(
                                            <FilaTabla bgColorPrincipal onClick={() => handleClick({codigo})}>
                                                <ColumnaTabla align='right'>{codigo}</ColumnaTabla>
                                                <ColumnaTabla>{nombre}</ColumnaTabla>
                                                <ColumnaTabla align='right'>{formatoMoneda(precio)}</ColumnaTabla>
                                            </FilaTabla>
                                        )
                                    })
                                }
                            </tbody>
                        </Tabla>
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Container>
        </Box>
      </Box> 
    )
}