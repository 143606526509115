import { useEffect, useState } from 'react';
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from '../firebase/firebaseConfig';


const useObtenerPedidos = (idPedidoSelect) => {

    const [misPedidos, setMisPedidos] = useState([]);  
    useEffect(() => {
         const consultarDocumentos = () => {

             const consultarPedido = query(
                 collection(db, 'pedidoscedifa'),
                 where('idPedido', '==', idPedidoSelect),
                 orderBy('fechaPedido', 'desc')
             );

            const unsuscribe = onSnapshot(
                consultarPedido,
                (querySnapshot) => {
                    setMisPedidos(querySnapshot.docs.map((documento) => {
                        return { ...documento.data()}
                    }))
                }
            );
            return unsuscribe
         }
         consultarDocumentos();
    }, [idPedidoSelect])

    return misPedidos;    
}

export default useObtenerPedidos;