import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export function SkeletonTablaRevisarPedidos(){
    const filas = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]
    return(
        <>
        <Stack spacing={1}>
                <div className="header-skeleton">
                    <Skeleton variant="rounded" width='15%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                    <Skeleton variant="rounded" width='50%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                    <Skeleton variant="rounded" width='10%' height={40} />
                </div>
                {
                    filas.map((data) => {
                        return(
                            <div className="header-skeleton" key={data}>
                                <Skeleton variant="rounded" width='15%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                                <Skeleton variant="rounded" width='50%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                                <Skeleton variant="rounded" width='10%' height={20} />
                            </div>
                        )
                    })
                }
                
            </Stack>
        
        </>
    )
}